import React, { useRef, useCallback } from 'react';
import Paper from "@material-ui/core/Paper";
import SaveIcon from '@material-ui/icons/Save';
import { 
    DataTypeProvider 
} from '@devexpress/dx-react-grid';
import {
    Grid,
    TableHeaderRow,
    VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import { 
    GridExporter 
} from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';

import AppTrace from 'AppTrace';
import Locale from 'locale/Locale';
import Charts from 'data/chart/Charts';

const t = Locale.getResourceString.bind(Locale);

export default (props) => {
    // Bail if we don't have anything to chart    
    if (props.chartId == null || !Charts.contains(props.chartId)) {
        return null;
    }

    // Try to lookup the format for the first forecast in the chart.  Default to currency.
    const chart = Charts.getById(props.chartId);
    const primaryYAxisForecasts = chart.primaryYAxisForecasts;
    const secondaryYAxisForecasts = chart.secondaryYAxisForecasts;
    const primaryNumberFormat = props.filters.lookups.forecastTypes[primaryYAxisForecasts[0]?.definition?.forecast_type_id]?.format ?? '{value:n2}';
    const secondaryNumberFormat = props.filters.lookups.forecastTypes[secondaryYAxisForecasts[0]?.definition?.forecast_type_id]?.format ?? '{value:n2}';

    const PrimaryYAxisValueTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={({ value }) => Locale.formatValue(value, primaryNumberFormat)}
            {...props}
        />
    );

    const SecondaryYAxisValueTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={({ value }) => Locale.formatValue(value, secondaryNumberFormat)}
            {...props}
        />
    );

    const exporterRef = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    const onSave = (workbook) => {
        // Trace the event
        AppTrace.traceInfo('The forecast data for a chart has been exported', AppTrace.categories.exportChart);

        let workbookName = (chart.title != null && chart.title.trim().length > 0) ?
            `${chart.title}.xlsx` :
            'Forecasts.xlsx';
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
        });
    };

    // Get columns
    let columns = [{ name: 'argument', title: props.filters?.lookups?.granularities[chart.granularityId]?.dataColumnCaption ?? 'Date' }];
    chart.forecasts.forEach(f => {
        columns.push({ name: f.id, title: f.caption });
    });

    return (
        <div className="app-chart-data-container">
            <div className="app-toolbar-button app-export-chart-data" title={t('header.exportChartData')} onClick={() => startExport()}>
                <SaveIcon />
            </div>
            <Paper>
                <Grid rows={chart.data} columns={columns}>
                    <PrimaryYAxisValueTypeProvider
                        for={primaryYAxisForecasts.map(f => f.id)}
                    />
                    <SecondaryYAxisValueTypeProvider
                        for={secondaryYAxisForecasts.map(f => f.id)}
                    />
                    <VirtualTable 
                        height="100%"
                    />
                    <TableHeaderRow />
                </Grid>
                <GridExporter
                    ref={exporterRef}
                    rows={chart.data}
                    columns={columns}
                    onSave={onSave}
                />
            </Paper>
        </div>
    );
}