import AppConfig from 'AppConfig';
import AuthToken from 'auth/AuthToken';

const axios = require('axios');

class FilterDataFactory {
    constructor() {
        this._filters = null;
    }    

    get() {
        return new Promise((resolve, reject) => {
            if (this._filters != null) {
                resolve(this._filters);
            }
            else {
                AuthToken
                    .getJwt()
                    .then(jwt => {
                        let authString = `Bearer ${jwt}`;
                        let url = `${AppConfig.environment.dataService.baseUrl}/filters`;

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        })
                    })
                    .then(response => {
                        this._filters = this._postProcess(response.data);
                        resolve(this._filters);
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(new Error(`Error getting filters: "${error.message}"`));
                    });
            }
        });
    }

    _postProcess(data) {
        // Create lookups from id to name for all lookups
        data.lookups = {};
        for (const [key, value] of Object.entries(data)) {
            if (key !== 'vintages' && key !== 'lookups') {
                data.lookups[key] = value.reduce((obj, cur) => {
                    // Reference the full object
                    obj[cur.id] = cur;
                    return obj;
                }, {});
            }
        }
        return data;
    }
}

const _instance = new FilterDataFactory();
Object.seal(_instance);

export default _instance;
