import enResources from './en';

import Impl from '../Impl';

export default class Impl_en extends Impl {
    get resources() {
        return enResources;
    }

    formatDate(moment) {
        return moment.locale('en').format('MM/DD/YYYY');
    }

    formatDateTime(moment) {
        return moment.locale('en').format('MM/DD/YYYY HH:00');
    }

    formatMomentGranularity(moment, granularity) {
        const granularities = {
            // Standard granularities
            'A': 'YYYY',
            'M': 'YYYY-MM',
            'D': 'YYYY-MM-DD',
            'H': 'YYYY-MM-DD HH',
            'MoY': 'MMMM',
            'HoD': 'HH',

            // Additional options
            'MoY-short': 'MM',
        };

        // Ensure we have a format mapping for the input granularity
        if (!granularities.hasOwnProperty(granularity)) {
            granularity = 'H';
        }

        return moment.locale('en').format(granularities[granularity]);
    }

    formatNumber(value, digits) {
        return value.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatPercent(value, digits) {
        return value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatCurrency(value) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
}
