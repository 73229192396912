import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppConfig from './AppConfig'

ReactDOM.render(
    (AppConfig.strictMode) ?
        (<React.StrictMode>
            <App />
        </React.StrictMode>) :
        (<App/>),
    document.getElementById('root')
);
