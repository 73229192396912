/////////////////////////////////////////////////////////////////////////////
// SessionState facilitates getting/setting UI state via the browser's
// sessionStorage object.
/////////////////////////////////////////////////////////////////////////////
class SessionState {
    get(key: string, defaults: Object, overrides: Object) {
        try {
            let sessionState = JSON.parse(window.sessionStorage.getItem(key));
            if (sessionState == null) {
                sessionState = {};
            }

            if (defaults == null) {
                defaults = {};
            }

            if (overrides == null) {
                overrides = {};
            }

            return Object.assign(Object.assign(defaults, sessionState), overrides);
        } catch (err) {
            console.error(err);
        }
        return {};
    }

    set(key: string, sessionState: Object) {
        // Merge incoming settings with any existing state for the same key
        // This can be helpful for sharing state across components
        let state = _instance.get(key);
        Object.assign(state, sessionState);

        // Save to sessionStorage
        window.sessionStorage.setItem(key, JSON.stringify(state));
    }
}

const _instance = new SessionState();
Object.freeze(_instance);

export default _instance;
