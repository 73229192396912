import AppTrace from 'AppTrace';

class ErrorState {
    _faultHandlers = [];
    _fault = null;
    _faultCategory = this.faultCategories.Default;
    _errorContinuationHandlers = [];

    // Gets the available fault categories
    get faultCategories() {
        const FaultCategories = {
            Default: 0,
            Fatal: 1,
        };

        return FaultCategories;
    }

    // Adds a function to be called when a fault is set or cleared
    addFaultHandler(faultHandler) {
        this._faultHandlers.push(faultHandler);
    }

    // Gets whether or not the app is faulted
    get isFaulted() {
        return this._fault !== null;
    }

    // Gets the current fault; null if there is no current fault
    get fault() {
        return this._fault;
    }

    // Gets the fault category
    get faultCategory() {
        return this._faultCategory;
    }

    // Sets an application fault
    setFault(fault, faultCategory) {
        // Store fault information
        this._fault = fault ?? 'An unknown error occurred';
        this._faultCategory = (faultCategory != null && Object.values(this.faultCategories).indexOf(faultCategory) !== -1) ? 
            faultCategory : 
            this.faultCategories.Default;

        // Trace the fault
        try {
            AppTrace.traceError(fault);
        }
        catch (error) {
            console.error(error);
        }

        this._notify();
    }

    clearFault() {
        // Clear fault information
        this._fault = null;
        this._faultCategory = this.faultCategories.Default;

        // Notify fault handlers
        this._notify();
    }

    _notify() {
        let stopNotifying = false;
        for (let i = 0; i < this._faultHandlers.length && !stopNotifying; ++i) {
            this._faultHandlers[i](this.isFaulted);
        }
    }
}

const _instance = new ErrorState();
Object.seal(_instance);

export default _instance;
