const Palette = Object.freeze({
    default: {
        _colors: [
            '#00848f',  // Primary button color
            '#21252B',  // Dark color from palette
            '#92000F',  // Dark inspire red
            '#5F27CD',  // Purple 3
            '#FFAF40',  // Warning yellow dark
            '#1671D9',  // Info blue dark
            '#28A150',  // Success green dark


            '#5CB0B7',  // Primary button color, less opacity
            '#606b7d',  // Secondary button color
            '#FF6B6B',  // Red 3
            '#9D75EB',  // Purple 2
            '#FECA57',  // Yellow 3
            '#54A0FF',  // Blue 3
            '#1DD1A1',  // Green 3

            '#005662',  // Primary button color, more opacity
            '#99A0AC',  // Secondary button color, less opacity
            '#FFA6A6',  // Red 2
            '#CFBEF0',  // Purple 1
            '#FEDF9A',  // Yellow 2
            '#98C6FF',  // Blue 2
            '#77E3C7',  // Green 2
        ],

        getColorByIndex(index) {
            return this._colors[index % this._colors.length];
        }
    },
});

export default Palette;