import AppConfig from 'AppConfig';
import AuthToken from 'auth/AuthToken';
import BusyState from 'BusyState';
import FilterDataFactory from 'data/filters/FilterDataFactory';

const axios = require('axios');

class AvailableForecastsDataFactory {
    constructor() {
        this._forecastsByTypeId = {};
    }

    get(forecastTypeId) {
        return new Promise((resolve, reject) => {
            // Check if we already have this data cached...
            if (this._forecastsByTypeId[forecastTypeId] != null) {
                resolve(this._forecastsByTypeId[forecastTypeId]);
            }
            else {
                // Save current busy state
                let isBusy = BusyState.isBusy;
                BusyState.isBusy = true;

                // If not cached, go get it
                AuthToken
                    .getJwt()
                    .then(jwt => {
                        let authString = `Bearer ${jwt}`;
                        let url = `${AppConfig.environment.dataService.baseUrl}/forecast/available?forecastTypeId=${forecastTypeId}`;

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        })
                    })
                    .then(response => {
                        // We received the forecasts.  Make sure we have filters to perform lookups.
                        return new Promise((resolve, reject) => {
                            FilterDataFactory
                                .get()
                                .then(filters => {
                                    resolve({ 
                                        forecasts: response.data,
                                        filters: filters
                                    });
                                })
                                .catch(error => {
                                    console.error(error.message);
                                    reject(new Error(`Error getting available forecasts: "${error.message}"`));
                                });
                        });
                    })
                    .then(obj => {
                        // Restore busy state
                        BusyState.isBusy = isBusy;

                        // Apply data and resolve
                        this._forecastsByTypeId[forecastTypeId] = this._postProcess(obj.forecasts, obj.filters);
                        resolve(this._forecastsByTypeId[forecastTypeId]);
                    })
                    .catch((error) => {
                        // Restore busy state
                        BusyState.isBusy = isBusy;

                        // Handle error
                        console.error(error.message);
                        reject(new Error(`Error getting available forecasts: "${error.message}"`));
                    });
            }
        });
    }

    ////////////////////////////////////////////////////////////////////////////
    // Use this method to sort filter values.
    //
    // This method will sort the dropdown filter values in an order that
    // is optimal based on the type of values in the column.
    ////////////////////////////////////////////////////////////////////////////
    sortFilterValues(values, columnName) {
        return (values == null) ? null :
            (columnName === 'vintage_year_nbr') ? values.sort((a, b) => b - a) :
            values.sort();
    }

    _postProcess(forecasts, filters) {
        //
        // Transform JSONTable back to objects, PLUS add lookup fields
        //
        forecasts.transformed = {};
        forecasts.transformed.rows = [];
        forecasts.transformed.allFilterValues = {};

        // Get all ID columns for which we should add name columns in the forecast
        // types grid.  The names come from lookups in the "filters" API result.
        // JSONTable columns that should have a lookup mapping will have the following
        // fields in the column's tag:
        // * lookupName
        // * nameColumnName
        // * nameColumnCaption
        let lookupIdColumns = Object.values(forecasts.metadata.columns).reduce((obj, col) => {
            // Add an allFilterValues object for the current column
            forecasts.transformed.allFilterValues[col.name] = {};
            // If we have a lookup, then add that to the returned collection AND add an allFilterValues lookup for that as well
            if (col?.tag?.lookupName != null && filters[col.tag.lookupName] != null) {
                obj[col.name] = col;
                forecasts.transformed.allFilterValues[col.tag.nameColumnName] = {};
            }
            return obj;
        }, {});

        // Perform initial transformations to produce a "transformed" array
        // of objects that can easily be found to grids, etc. The idea is to 
        // turn the JSONTable data into an array of object, like:
        // [ 
        //     { area_of_the_world_id: 1, forecast_type_id: 1, scenario_id: 1, ... },
        //     { area_of_the_world_id: 1, forecast_type_id: 1, scenario_id: 2, ... },
        //     { area_of_the_world_id: 1, forecast_type_id: 2, scenario_id: 2, ... },
        //     ...
        // ]
        for (let i = 0; i < forecasts.rows.length; ++i) {
            let row = forecasts.rows[i];
            let obj = Object.values(forecasts.metadata.columns).reduce((obj, col) => {
                // First, assign attributes that are actually present in the JSONTable
                // and add a filter-values object
                let value = row[col.index];
                obj[col.name] = value;
                if (value != null) {
                    forecasts.transformed.allFilterValues[col.name][value] = true;
                }

                // Next, add name fields for all lookup ID columns found above
                if (lookupIdColumns[col.name] != null) {
                    let value = filters.lookups[col.tag.lookupName][row[col.index]].name;
                    obj[col.tag.nameColumnName] = value;
                    if (value != null) {
                        forecasts.transformed.allFilterValues[col.tag.nameColumnName][value] = true;
                    }
                    // e.g. obj['senario_name'] = filters.lookups['scenarios'][row[10]].name;
                }
                
                return obj;
            }, {});
            forecasts.transformed.rows.push(obj);
        }

        // Lastly, we will create a set of "transformed" metadata columns by
        // forming the union of the API's JSONTable metadata columns and the
        // lookup ID columns that we identified above.
        forecasts.transformed.columns = Object.assign({}, forecasts.metadata.columns);
        Object.values(lookupIdColumns).reduce((columns, col) => {
            columns[col.tag.nameColumnName] = {
                caption: col.tag.nameColumnCaption,
                format: null,
                index: col.index + 0.5,  // Just after the ID column, but before the next JSONTable column
                name: col.tag.nameColumnName,
                type: 'string',
                visibility: 'visible',
            };
            return columns;
        }, forecasts.transformed.columns);

        // Transform all filter-value objects to sorted arrays
        for (const columnName in forecasts.transformed.allFilterValues) {
            forecasts.transformed.allFilterValues[columnName] = this.sortFilterValues(Object.keys(forecasts.transformed.allFilterValues[columnName]), columnName);
        }

        // Return transformed data
        return forecasts;
    }
}

const _instance = new AvailableForecastsDataFactory();
Object.seal(_instance);

export default _instance;
