// Local debugging settings
const local = {
    name: 'Local',
    isRemote: false,
    dataService: {
        // Local debugging using Visual Studio and IIS
        baseUrl: 'https://localhost:44301',
        // Local debugging using Kestrel
        //baseUrl: 'https://localhost:5001',
        // Local debugging using Docker
        //baseUrl: 'https://localhost:????',
        // Local debugging using published Dev web API
        //baseUrl: 'https://refcaseapi.emidev.hasops.com',
    },
};

// Epiphany dev settings
const epiphanyDev = {
    name: 'EpiphanyDev',
    isRemote: true,
    dataService: {
        baseUrl: 'https://refcaseapi.emidev.hasops.com',
    },
};

// Epiphany prod settings
const epiphanyProd = {
    name: 'EpiphanyProd',
    isRemote: true,
    dataService: {
        baseUrl: 'https://api.refc.emi.solutions.hitachienergy.com',
    },
};

// Determine API url based on hostname
const config = (window.location.hostname === 'localhost') ? local : 
    (window.location.hostname.includes('web.refc.emi.solutions.hitachienergy.com')) ? epiphanyProd :
    (window.location.hostname.includes('refcaseui.emidev.hasops.com')) ? epiphanyDev : 
    epiphanyDev;

class AppConfig {
    /////////////////////////////////////////////////////////////////////////
    // Common config values
    /////////////////////////////////////////////////////////////////////////

    get strictMode() {
        return false
    }

    // Company Info
    get company() {
        return {
            name: 'Hitachi Energy USA Inc.',
            address: '901 Main Campus Drive Raleigh, North Carolina 27606',
            refcaseUrl: 'https://www.hitachiabb-powergrids.com/offering/product-and-system/energy-planning-trading/market-intelligence-services/market-advisory-services'
        }
    }

    // Support contact info
    get support() {
        return {
            name: 'Insights Support',
            email: 'Insights-Support.PGES@hitachienergy.com',
            phone: '1.888.968.8860',
        }
    }

    get security() {
        return {
            idleTimeout: 30 * 60 * 1000,  // 30 minutes
        }
    }

    get limits() {
        return {
            maxCharts: 20,
            maxForecasts: 30,
        }
    }

    // Append environment-specific configrations
    get environment() {
        return config;
    }
};

const _instance = new AppConfig();
Object.seal(_instance);

export default _instance;
