import { v4 as uuidv4 } from 'uuid';
import BusyState from 'BusyState';
import ForecastDataFactory from 'data/forecasts/ForecastDataFactory';
import Locale from 'locale/Locale';

import AxisTypes from './AxisTypes';
import Charts from './Charts';
import Forecast from './Forecast';

const t = Locale.getResourceString.bind(Locale);

export default class Chart {
    constructor(granularityId = null, title = Chart.defaultTitle) {
        this._id = uuidv4();

        this.granularityId = granularityId;
        this.title = title;

        this._forecasts = [];

        this._data = []; 
        this._rowIndex = {};

        // Keeps track of whether or not we're already loading all chart forecast data for this chart
        this._isLoadingAll = false;
    }
    
    static fromJSON(json) {
        // Recreate the Chart
        let obj = JSON.parse(json);
        let chart = new Chart(obj._granularityId, obj._title);
        chart._id = obj._id;
        chart._forecasts = obj._forecasts.map(f => Forecast.fromJSON(f));

        return chart;
    }

    toJSON() {
        // Create a copy of the object and exclude the data, rowIndex, and isBusy fields
        let obj = Object.assign({}, this);
        obj._forecasts = this._forecasts.map(f => f.toJSON());
        obj._data = [];
        obj._rowIndex = {};
        obj._isLoadingAll = false;

        return JSON.stringify(obj);
    }

    get id() {
        return this._id;
    }

    get granularityId() {
        return this._granularityId;
    }
    set granularityId(value) {
        this._granularityId = value;
    }

    get title() {
        return this._title;
    }
    set title(value) {
        this._title = value;
    }

    get forecasts() {
        return this._forecasts;
    }
    
    get data() {
        // Slice the data to protect users from our modifications, and
        // to protect our internal data from external modifications
        return this._data.slice();
    }

    get primaryYAxisForecasts() {
        return this.forecasts.filter(f => f.axisType === AxisTypes.Primary);
    }

    get secondaryYAxisForecasts() {
        return this.forecasts.filter(f => f.axisType === AxisTypes.Secondary);
    }
    
    addForecast(forecast) {
        // Check if the object is a forecast.  If it is not, convert it to one
        if (!(forecast instanceof Forecast)) {
            forecast = Forecast.createFrom(forecast);
        }

        // Add the forecast to the collection
        this._forecasts.push(forecast);

        // Return the added forecast
        return forecast;
    }

    ////////////////////////////////////////////////////////////////////////////
    // This method applies the specified forecasts to the forecasts collection
    // in this Chart.  Forecasts that are not already present are added.
    // Forecasts that are already present kept.  The order of the forecasts
    // in the chart, when this method completes, matches the order of the 
    // forecasts specified in the input array.
    ////////////////////////////////////////////////////////////////////////////
    applyEditorForecasts(forecasts) {
        let updatedForecasts = [];
        forecasts.forEach(editorForecast => {
            let chartForecast = this._forecasts.find(f => f.id === editorForecast.id);
            if (chartForecast != null) {
                // Update existing forecasts
                chartForecast.update(editorForecast);
                updatedForecasts.push(chartForecast);
            }
            else {
                // Add new forecasts
                chartForecast = this.addForecast(editorForecast);
                updatedForecasts.push(chartForecast);
            }
        });

        // Swap out our forecasts collection and clean up
        if (Array.isArray(this._forecasts)) {
            this._forecasts.length = 0;
        }
        this._forecasts = updatedForecasts;
    }

    removeForecast(forecast) {
        let index = this.forecasts.findIndex(f => f.id === forecast.id);
        if (index !== -1) {
            // First, remove the forecast from our collection
            this.forecasts.splice(index, 1);

            //
            // Clean up chart data
            //

            // Remove chart data properties associated with the forecast
            for (let i = 0; i < this._data.length; ++i) {
                delete this._data[i][forecast.id];
            }

            // Filter to contain only the rows that reference some other
            // forecast.  This will be the rows with more than 3 properties
            // (apiArgument, argument, moment).
            this._data = this._data.filter(row => (Object.keys(row).length > 3));

            // Rebuild the row index
            this._rowIndex = this._data.reduce((obj, chartDataRow) => {
                obj[chartDataRow.moment.valueOf()] = chartDataRow;
                return obj;
            }, {});
        }
    }

    clear() {
        this._rowIndex = {};
        if (Array.isArray(this._data)) {
            this._data.length = 0;
        }
        if (Array.isArray(this._forecasts)) {
            this._forecasts.forEach(f => f.clear());
            this._forecasts.length = 0;
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    loadAllForecastData() {
        return new Promise((resolve, reject) => {
            let newForecasts = this._forecasts.filter(f => f.data == null || f.data.length === 0);
            if (this._isLoadingAll || newForecasts.length === 0) {
                resolve(this);
            }
            else {
                // Set app busy state
                this._isLoadingAll = true;
                BusyState.isBusy = true;

                // Load forecast data one at a time for all forecasts
                this._loadForecastData(newForecasts, 0, resolve, reject);
            }
        });
    }



    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    static get defaultTitle() {
        return t('charts.defaultTitle')(Charts.all.length + 1);
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    _loadForecastData(forecasts, index, resolve, reject) {
        // If we have additional forecasts for which to load data...
        if (index < forecasts.length) {
            // Update the custom progress text
            BusyState.message = `Getting forecast ${index + 1} of ${forecasts.length}`;

            // Load the data for the current forecast
            let forecast = forecasts[index];
            ForecastDataFactory
                .get(forecast.definition)
                .then(data => {
                    // Apply the data, and merge it into the aggregated chart data
                    forecast.data = data;
                    this._mergeForecastData(forecast);

                    // Recursively request data for the next forecast
                    this._loadForecastData(forecasts, ++index, resolve, reject);
                })
                .catch(error => {
                    console.error(error.message);
                    
                    this._isLoadingAll = false;
                    BusyState.isBusy = false;
                    BusyState.message = null;
                    reject(error);
                });
        }
        else {
            // Restore busy state and resolve
            this._isLoadingAll = false;
            BusyState.isBusy = false;
            BusyState.message = null;
            resolve(this);
        }
    }

    _mergeForecastData(forecast) {
        // Track new rows that are before the start of any existing data
        let newChartDataRows = [];

        // Add the data, updating rowIndex as needed
        for (let i = 0; i < forecast.data.length; ++i) {
            // Get the forecast data row
            let forecastDataRow = forecast.data[i];

            //
            // Look for an existing row with the same timestamp
            //
            let key = forecastDataRow.moment.valueOf();
            if (this._rowIndex.hasOwnProperty(key)) {
                // Merge this forecast's data into the existing record
                this._rowIndex[key][forecast.id] = forecastDataRow.value;
            }
            // Otherwise, we will need to create a new row and determine where to place it
            else {
                // Create a new chart data record for the current forecast data record
                let chartDataRow = {
                    apiArgument: forecastDataRow.apiArgument,
                    argument: forecastDataRow.argument,
                    moment: forecastDataRow.moment,
                };
                chartDataRow[forecast.id] = forecastDataRow.value;

                // Make a couple of assumptions about the forecast data:
                // 1. It is ordered by timestamp
                // 2. There are no gaps, so a record not already found is before or after any existing merged chart data
                if (this._data.length === 0 || chartDataRow.moment.isBefore(this._data[0].moment)) {
                    // Records before the start of existing data are added to a secondary array
                    newChartDataRows.push(chartDataRow);
                }
                else if (chartDataRow.moment.isAfter(this._data[this._data.length - 1].moment)) {
                    // Update row index and add chart data row to chart data
                    this._rowIndex[key] = chartDataRow;
                    this._data.push(chartDataRow);
                }
                else {
                    throw new Error("Assumption failed");
                }
            }
        }

        // Add all new chart data rows to the front of our chart data array, keeping track of the row indexes
        for (let i = newChartDataRows.length - 1; i >= 0; --i) {
            let chartDataRow = newChartDataRows[i];
            let key = chartDataRow.moment.valueOf();
            this._rowIndex[key] = chartDataRow;
            this._data.unshift(chartDataRow);
        }
    }
}