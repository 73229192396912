class BusyState {
    _handlers = [];
    _isBusy = false; // Whether or not the application is busy
    _message = null; // Custom loading/progress message

    // Adds a function to be called when the state changes
    addHandler(handler) {
        this._handlers.push(handler);
    }

    // Gets whether or not the app is busy
    get isBusy() {
        return this._isBusy;
    }

    // Sets whether or not the app is busy
    set isBusy(isBusy) {
        if (this.isBusy !== isBusy) {
            this._isBusy = isBusy;
            this._notify();
        }
    }

    // Gets any custom loading message - null if there's no custom message
    get message() {
        return this._message;
    }

    // Sets a custom loading message
    set message(value) {
        if (value !== this._message) {
            this._message = value;
            this._notify();
        }
    }

    _notify() {
        let stopNotifying = false;
        for (let i = 0; i < this._handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || this._handlers[i](this.isBusy, this.message);
        }
    }
}

const _instance = new BusyState();
Object.seal(_instance);

export default _instance;
