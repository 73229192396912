import AppConfig from 'AppConfig';

const axios = require('axios');

class AuthToken {
    constructor() {
        this.reset();
    }

    get decodedPayload() {
        return this._decodedPayload;
    }

    set decodedPayload(value) {
        this._decodedPayload = value;
    }

    get encodedPayload() {
        return this._encodedPayload;
    }

    set encodedPayload(value) {
        this._encodedPayload = value;
    }

    reset() {
        this._decodedPayload = null;
        this._encodedPayload = null;
        this._jwt = null;
    }

    getJwt() {
        return new Promise((resolve, reject) => {
            // Confirm that we have the Salesforce payload
            if (this.decodedPayload == null) {
                reject(new Error("Can't request jwt before obtaining Salesforce payload"));
            }
            // Check the cached JWT.  If it's valid and not expired, return it
            else if (!this._isCurrentJwtExpired) {
                resolve(this._jwt);
            }
            else {
                // Otherwise, request a JWT
                let claims = {
                    access_token: this.decodedPayload.access_token,
                    email: this.decodedPayload.email,
                    id: this.decodedPayload.id,
                    user_id: this.decodedPayload.user_id,
                    username: this.decodedPayload.username
                };
                let url = `${AppConfig.environment.dataService.baseUrl}/token?claims=${btoa(JSON.stringify(claims))}`;

                axios.get(url, {
                    headers: {
                        Accept: "application/json",
                        "cache-control": "no-cache",
                    }
                })
                .then(response => {
                    this._jwt = response.data;
                    resolve(this._jwt);
                })
                .catch((error) => {
                    reject(new Error("Error getting jwt"));
                });
            }
        });
    }

    get _isCurrentJwtExpired() {
        // If we don't have one, then yes, it's expired
        if (this._jwt == null) {
            return true;
        }

        // We need 3 segments in the JWT
        var tokens = this._jwt.split('.');
        if (tokens.length !== 3) {
            return true;
        }

        // Extract the payload
        var payload = JSON.parse(atob(tokens[1]));
        if (!payload.hasOwnProperty('exp')) {
            return true;
        }

        // Compare dates, accounting for clock skew up to 5 minutes
        var fiveMinutesAgo = new Date();
        fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);
        if (fiveMinutesAgo.getTime() > payload.exp * 1000) {
            return true;
        }

        return false;
    }
}

const _instance = new AuthToken();
Object.seal(_instance);

export default _instance;