import React, { useState } from 'react';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import CopyrightIcon from '@material-ui/icons/Copyright';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider } from 'webcore-ux/nextgen/theme';
import { LoadingIndicator, Stripe } from 'webcore-ux/nextgen/components';
import Edit from 'webcore-ux/nextgen/components/Icons/Edit';
import Trash from 'webcore-ux/nextgen/components/Icons/Trash';
import PlusInCircle from 'webcore-ux/nextgen/components/Icons/PlusInCircle';
import MenuIcon from 'webcore-ux/nextgen/components/Icons/Menu';

import { ResponsiveContainer } from 'recharts';

// TODO: Add these if we support filters
//import { SimpleTray, SimpleTrayContext } from 'webcore-ux/nextgen/components';
// import Filter from 'webcore-ux/nextgen/components/Icons/Filter';

import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import AuthToken from 'auth/AuthToken';
import BusyState from 'BusyState';
import ErrorState from 'ErrorState';
import FilterDataFactory from 'data/filters/FilterDataFactory';
import Locale from 'locale/Locale';
import SessionState from 'SessionState';

import Chart from 'data/chart/Chart';
import Charts from 'data/chart/Charts';

import FaultDialog from 'components/FaultDialog';
import EditChartDialog from 'components/EditChartDialog';
import AddForecastsDialog from 'components/AddForecastsDialog';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import RefcaseChart from 'components/RefcaseChart';
import OSSAttribution from 'components/OSSAttribution';

import './App.scss';

const t = Locale.getResourceString.bind(Locale);

class App extends React.Component {
    constructor(props) {
        super(props);

        this.sessionStateKey = 'refcase-visualization.App';

        // Initialize state
        let defaultInitialState = {
            isAuthenticated: false,
            isViewChartData: false,

            localeName: Locale.localeName,
            charts: null,
        };
        let stateOverrides = {
            isAuthorized: false,
            isSignedOut: false,

            isBusy: false,
            isFaulted: false,
            isBusyMessage: t('loadIndicator.caption'),
            isAddingChart: false,
            isEditingChart: false,
            isAddForecastsVisible: false,
            isOSSAttributionVisible: false,
        };

        // Restore state; override with specific values we want applied
        this.state = SessionState.get(this.sessionStateKey, defaultInitialState, stateOverrides);

        // Configure locale
        Locale.localeName = this.state.localeName;
        if (this.state.charts != null) {
            Charts.restoreFromJSON(this.state.charts);
        }

        // IdleTimer reference
        this._idleTimer = null;

        // Store available filters/lookups.
        this._filters = {
            lookups: {
                forecastTypes: {}
            }
        };

        // We'll use this variable to store a new chart object during creation
        this._newChart = null;

        // Add an ErrorState handler
        ErrorState.addFaultHandler((isFaulted) => {
            this.setState({
                isFaulted: isFaulted,
            });
        });

        // Add a BusyState handler to watch for whether or not we're loading data
        BusyState.addHandler((isBusy, message) => {
            this.setState({
                isBusy: isBusy,
                isBusyMessage: message ?? t('loadIndicator.caption'),
            });
        });

        // Attach an event handler for unauthorized setups
        window.onUnauthorizedSetup = () => {
            if (!this.state.isSignedOut) {
                window.SFIDWidget.login();
            }
        };

        // Attach event handlers for when users sign in and sign out
        window.appOnLogin = (openid_response, encodedPayload) => {
            // Save token - we must do this before requesting JWTs, even for AppTrace
            AuthToken.decodedPayload = openid_response;
            AuthToken.encodedPayload = encodedPayload;

            // Trace the login
            AppTrace.traceInfo('User authenticated', AppTrace.categories.authenticate);

            // Note that we're authenticated
            this.setState({
                isAuthenticated: true,
            });

            // Set busy state to load filters
            BusyState.isBusy = true;

            // Request filter data
            FilterDataFactory
                .get()
                .then(filters => {
                    // Reset busy state - we must do this BEFORE we set isAuthorized, 
                    // otherwise the load indicator will hide while any chart data 
                    // is loaded.
                    BusyState.isBusy = false;

                    // Check filters data for at least one vintage - if there are no vintages
                    // then the user is not authorized for data
                    this._filters = filters;
                    this.setState({
                        isAuthorized: filters.vintages?.length > 0,
                        isSignedOut: false,
                    });

                    // Check whether or not we have any vintages
                    if (filters.vintages == null || filters.vintages.length === 0) {
                        ErrorState.setFault("No authorized vintages", ErrorState.faultCategories.Fatal);
                    }
                    // If there are no charts in our collection, then add a chart
                    else if (Charts.all.length === 0) {
                        this._onAddChart();
                    }
                })
                .catch(error => {
                    console.error(error.message);
                    ErrorState.setFault(error.message, ErrorState.faultCategories.Fatal);
                    BusyState.isBusy = false;
                });
        }

        window.appOnLogout = () => {
            // Trace the logout
            AppTrace.traceInfo('User logged out', AppTrace.categories.signOut);

            // Reset token
            AuthToken.reset();

            // Clear all charts
            Charts.clear();

            this.setState({
                isAuthenticated: false,
                isAuthorized: false,
                isSignedOut: true,

                isFaulted: false,
                isBusy: false,
                isBusyMessage: t('loadIndicator.caption'),
                isAddingChart: false,
                isEditingChart: false,
                isAddForecastsVisible: false,

                charts: Charts.toJSON(),
            });
        }

        this._onAddChart = this._onAddChart.bind(this);
        this._onAddForecasts = this._onAddForecasts.bind(this);
    }



    _onAddChart(e) {
        // Don't perform default event handling
        if (e != null) {
            e.preventDefault();
        }

        // Create a new chart to be edited.  Omit the granularity
        // so it can be set automatically by the AddForecastsDialog
        // based on the selected forecast type.
        this._newChart = new Chart();
        this.setState({ isAddingChart: true });
    };

    _onAddForecasts() {
        this.setState({ isAddForecastsVisible: true })
    }

    ////////////////////////////////////////////////////////////////////////////
    // Renders the app-wide load indicator
    ////////////////////////////////////////////////////////////////////////////
    LoadIndicator = (props) => {
        return (
            <Dialog className="load-indicator" disableBackdropClick={true} disableEscapeKeyDown={true} open={props.isOpen}>
                <div className="load-indicator-content">
                    <LoadingIndicator />
                    <span>{props.isOpen ? this.state.isBusyMessage : ''}</span>
                </div>
            </Dialog>
        );
    }

    ////////////////////////////////////////////////////////////////////////////
    // Renders Home route content
    ////////////////////////////////////////////////////////////////////////////
    Home = (props) => {
        return (
            <div className="app-home">
                <Card className="app-home-card">
                    <CardContent>
                        <Typography className="app-home-card-header" component="h6" variant="h6" gutterBottom>
                            {t('header.productName')}
                        </Typography>

                        <Typography className="" component="p" gutterBottom>
                            Welcome to {t('header.productName')}.  This tool allows you to easily find, chart, and download your <Link href={AppConfig.company.refcaseUrl} target="_blank" rel="noopener">Reference Case</Link> forecasts.
                        </Typography>
                        
                        <Typography className="" component="p">
                            {
                                this.state.isAuthenticated ?
                                    (<span>Click <Link href="#" onClick={this._onAddChart}>here</Link> to get started with a new chart.</span>) :
                                    (<span>Click <Link href="#" onClick={window.signIn}>here</Link> to sign in and get started.</span>)
                            }
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }

    NavigationMenu = (props) => {
        const [anchorElement, setAnchorElement] = useState(null);

        // Get our tray menu items
        let chartMenuItems = [];
        if (this.state.isAuthenticated) {
            chartMenuItems = Charts.all.map(chart => {
                return {
                    id: chart.id,
                    title: chart.title,
                    link: `/#/${chart.id}`,
                };
            });
        }

        let menu = (chartMenuItems.length > 0) && (
            <Menu
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={() => setAnchorElement(null)}
            >
                {
                    chartMenuItems.map(tmi => 
                        <MenuItem key={tmi.id} onClick={() => { 
                            setAnchorElement(null); 
                            window.location.href=tmi.link; 
                        }}>
                            {tmi.title}
                        </MenuItem>)
                }
            </Menu>
        );

        return (
            <>
                <div className="app-toolbar-button app-menu-button" title={t('header.menu')} aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorElement(event.target)}>
                    <MenuIcon />
                    <span>
                        {chartMenuItems.length === 1 ? t('charts.singular')(1) : t('charts.plural')(chartMenuItems.length)}
                    </span>
                </div>

                {menu}
            </>
        );
    }

    ////////////////////////////////////////////////////////////////////////////
    // Renders the components needed to add a chart
    ////////////////////////////////////////////////////////////////////////////
    NewChart = (props) => {
        const [anchorElement, setAnchorElement] = useState(null);

        const onAddCancel = () => {
            this.setState({ isAddingChart: false });
        };

        const onAddOk = (chart) => {
            // Trace the event
            AppTrace.traceInfo('A chart has been added', AppTrace.categories.addChart);

            // Add the chart to the collection
            Charts.add(chart);
            this.setState({
                isAddingChart: false,
                charts: Charts.toJSON(),
            });

            // Navigate to the chart
            window.location = `/#/${chart.id}`;

            // Load all forecast data and then draw the chart
            chart.loadAllForecastData()
                .catch(error => {
                    console.error(error.message);
                    ErrorState.setFault(`Error getting chart data: "${error.message}"`);
                });
        };

        // Get the chart ID from the route, and then lookup the chart
        let chartId = props.routeProps?.match.params.chartId;
        let chart = Charts.getById(chartId);

        // Render content only if we're authorized
        return this.state.isAuthorized && (
            <>
                <div 
                    className="app-toolbar-button app-add-chart" 
                    title={t('header.addChart')} 
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={(event) => setAnchorElement(event.target)}
                >
                    <PlusInCircle />
                </div>

                <Menu
                    anchorEl={anchorElement}
                    keepMounted
                    open={Boolean(anchorElement)}
                    onClose={() => setAnchorElement(null)}
                >
                    <MenuItem 
                        disabled={Charts.all.length >= AppConfig.limits.maxCharts} 
                        onClick={() => { this._onAddChart(); setAnchorElement(null); }}
                    >
                        {t('header.addNewChart')}
                    </MenuItem>
                    
                    {chart && (
                    <MenuItem
                        disabled={chart.forecasts.length >= AppConfig.limits.maxForecasts} 
                        onClick={() => { this._onAddForecasts(); setAnchorElement(null); }}
                    >
                        {t('header.addForecastsToChart')}
                    </MenuItem>)}
                </Menu>

                <EditChartDialog
                    isOpen={this.state.isAddingChart}
                    isAddingChart={true}
                    filters={this._filters}
                    chart={this._newChart}
                    onCancel={onAddCancel}
                    onOk={(chart) => onAddOk(chart)}
                />
            </>
        );
    }

    SelectedChart = (props) => {
        // Get the chart ID from the route, and then lookup the chart
        let chartId = props.routeProps?.match.params.chartId;
        let chart = Charts.getById(chartId);
        let shouldRenderChart = chart != null && this.state.isAuthorized && !this.state.isFaulted;

        const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

        // Edit => OK event handler
        const onEditOk = (chart) => {
            // Trace the event
            AppTrace.traceInfo('A chart has been edited', AppTrace.categories.editChart);

            // Close edit dialog
            this.setState({
                isEditingChart: false,
                charts: Charts.toJSON(),
            });

            // Load all forecast data and then draw the chart
            chart.loadAllForecastData()
                .catch(error => {
                    console.error(error.message);
                    ErrorState.setFault(`Error getting chart data: "${error.message}"`);
                });
        }

        // Delete => OK event handler
        const onDeleteOk = () => {
            // Trace the event
            AppTrace.traceInfo('A chart has been deleted', AppTrace.categories.deleteChart);

            // Close confirmation dialog
            setIsConfirmationVisible(false);

            // Find the chart in the Charts collection
            let index = Charts.indexOf(chart);

            // Remove the chart
            Charts.remove(chart);
            this.setState({ charts: Charts.toJSON() })

            // Update the current route
            let newRoute = '/';
            if (index >= 0) {
                if (index < Charts.all.length)
                    newRoute = `/${Charts.all[index].id}`;
                else if (((index - 1) >= 0) && ((index - 1) < Charts.all.length))
                    newRoute = `/${Charts.all[index - 1].id}`;
            }
            props.routeProps.history.push(newRoute);
        }

        if (shouldRenderChart) {
            // Load all forecast data for the chart
            chart.loadAllForecastData()
                .catch(error => {
                    console.error(error.message);
                    ErrorState.setFault(`Error getting chart data: "${error.message}"`);
                });
        }
        else if (chart == null) {
            // We don't know about the requested chart, so redirect
            let newRoute = (Charts.all.length > 0) ?
                `/${Charts.all[0].id}` :
                '/';
            props.routeProps.history.push(newRoute);
        }

        return shouldRenderChart && (
            <>
                {/* Chart filters are not required in MVP1 */}
                {/* <div
                    className="app-toolbar-button app-filter"
                    title={t('filters.toggleFilters')}
                    onClick={() => this.setState({ isSimpleTrayVisible: !this.state.isSimpleTrayVisible })}
                >
                    <Filter />
                </div> */}

                <div className="app-toolbar-button app-view-chart-data" title={t('header.toggleChartData')} onClick={() => this.setState({ isViewChartData: !this.state.isViewChartData })}>
                    { (this.state.isViewChartData) ? (<GridOnIcon fontSize="small" />) : (<GridOffIcon fontSize="small" />) }
                </div>
                <div className="app-toolbar-button app-edit-chart" title={t('header.editChart')} onClick={() => this.setState({ isEditingChart: true })}>
                    <Edit />
                </div>
                <div className="app-toolbar-button app-delete-chart" title={t('header.deleteChart')} onClick={() => setIsConfirmationVisible(true)}>
                    <Trash />
                </div>

                <RefcaseChart
                    filters={this._filters}
                    chartId={chartId}
                    isViewChartData={this.state.isViewChartData}
                />

                <EditChartDialog
                    isOpen={this.state.isEditingChart}
                    filters={this._filters}
                    chart={chart}
                    onCancel={() => this.setState({ isEditingChart: false })}
                    onOk={(chart) => onEditOk(chart)}
                />

                {this._renderNewForecasts(props.routeProps)}

                <DeleteConfirmationDialog
                    chart={chart}
                    isOpen={isConfirmationVisible}
                    onClose={() => setIsConfirmationVisible(false)}
                    onCancel={() => setIsConfirmationVisible(false)}
                    onOk={onDeleteOk}
                />
            </>
        );
    }

    _renderNewForecasts(routeProps) {
        // Edit => OK event handler
        const onOk = (chart, newForecasts) => {
            // Trace the event
            AppTrace.traceInfo('A forecast has been added to a chart', AppTrace.categories.addForecast);

            // Add all new forecasts to the chart
            newForecasts
                .filter(a => chart.forecasts.findIndex(b => b.id === a.id) === -1)
                .forEach(f => chart.addForecast(f));

            // Close edit dialog, save current charts
            this.setState({
                isAddForecastsVisible: false,
                charts: Charts.toJSON(),
            });

            // Load all forecast data and then draw the chart
            chart.loadAllForecastData()
                .catch(error => {
                    console.error(error.message);
                    ErrorState.setFault(`Error getting chart data: "${error.message}"`);
                });
        }

        // Get the chart ID from the route, and then lookup the chart
        let chartId = routeProps?.match.params.chartId;
        let chart = Charts.getById(chartId);
        let shouldRenderDialog =
            // We need a chart
            chart != null &&
            // We must be authorized
            this.state.isAuthorized &&
            // Only render if visible - this will clear out selection state between draws
            this.state.isAddForecastsVisible;

        return shouldRenderDialog && (
            <AddForecastsDialog
                isOpen={this.state.isAddForecastsVisible}
                filters={this._filters}
                chart={chart}
                existingForecasts={chart.forecasts}
                onCancel={() => this.setState({ isAddForecastsVisible: false })}
                onOk={onOk}
            />
        );
    }

    _renderOSSAttribution() {
        return (
            <>
                <div 
                    className="app-toolbar-button app-copyright" 
                    title={t('header.copyright')(AppConfig.company.name)} 
                    onClick={() => this.setState({ isOSSAttributionVisible: !this.state.isOSSAttributionVisible }) }
                >
                    <CopyrightIcon />
                </div>
                {this.state.isOSSAttributionVisible && (<OSSAttribution onClose={() => this.setState({ isOSSAttributionVisible: false })} />)}
            </>
        );
    }



    componentDidMount() {
        // Wait until the component has mounted to show the login button
        window.document.getElementById('salesforce-login').style.display = 'block';
        window.document.getElementById('sfid-signin-text').innerHTML = `&nbsp;${t('header.signIn')}`;
        window.document.getElementById('sfid-signout-text').innerHTML = `&nbsp;${t('header.signOut')}`;
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(this.sessionStateKey, this.state);
    }

    render() {
        return (
            <Router>
                <ThemeProvider>
                    <IdleTimer
                        ref={ref => { this._idleTimer = ref }}
                        timeout={AppConfig.security.idleTimeout}
                        onIdle={() => this.state.isAuthenticated && window.signOut()}
                        debounce={250}
                    />

                    <FaultDialog 
                        isFaulted={this.state.isFaulted}
                        onRefresh={() => window.location.reload()}
                        onOk={() => ErrorState.clearFault()}
                        onSignOut={() => window.signOut()}
                    />

                    <this.LoadIndicator 
                        isOpen={!this.state.isFaulted && this.state.isBusy}
                    />

                    <Stripe
                        productName={t('header.productName')}
                    />

                    <this.NavigationMenu />

                    <div className="app-content">
                        {this._renderOSSAttribution()}

                        <ResponsiveContainer>
                            <Switch>
                                <Route
                                    path={'/:chartId([\\da-z]{8}-[\\da-z]{4}-[\\da-z]{4}-[\\da-z]{4}-[\\da-z]{12})'}
                                    render={(routeProps) => {
                                        return (
                                            <>
                                                <this.NewChart routeProps={routeProps} />
                                                <this.SelectedChart routeProps={routeProps} />
                                            </>
                                        );
                                    }}
                                />
                                <Route path={'/home'}>
                                    <>
                                        <this.NewChart />
                                        <this.Home />
                                    </>
                                </Route>
                                <Route path="*">
                                    <Redirect to="/home" />
                                </Route>
                            </Switch>
                        </ResponsiveContainer>

                        {/* Chart filters are not required in MVP1 */}
                        {/* <SimpleTrayContext.Provider value={ { open: this.state.isSimpleTrayVisible, setOpen: () => { this.setState({ isSimpleTrayVisible: false }); } }}>
                            <SimpleTray
                                className="app-filter-tray"
                                anchor="right"
                                hideBackdrop={true}
                                showBackButton={false}
                                title={t('filters.title')}
                                onClose={() => this.setState({ isSimpleTrayVisible: false })}
                            >
                                <span>Filters</span>
                            </SimpleTray>
                        </SimpleTrayContext.Provider> */}

                    </div>
                </ThemeProvider>
            </Router>
        );
    }
}

export default App;
