export default {
    general: {
        notApplicable: "N/A",
    },

    header: {
        productName: 'Reference Case Visualization',

        menu: 'Select a chart',

        signIn: 'Sign in',
        signOut: 'Sign out',

        copyright: (companyName) => `Copyright 2004 - ${new Date().getFullYear()} ${companyName}`,
        addChart: 'Add a new chart or forecast...',
        addNewChart: 'Add New Chart',
        addForecastsToChart: 'Add Forecasts to Chart',
        editChart: 'Edit the current chart',
        deleteChart: 'Delete the current chart',
        toggleChartData: 'Show/hide chart data',
        exportChartData: 'Export chart forecasts',
    },

    licensing: {
        caption: 'Copyright and Licensing',
        thirdParty: 'THIRD PARTY NOTICES AND INFORMATION',
        thisProductMayInclude: 'This software may include or incorporate material from the files below (collectively, third party free/open source software "FOSS"). Please note: {0} is not the original author of the FOSS. The URL for the original file source, the original copyright notice and the license under which {0} received FOSS are set forth below together with the full text of such license. Such notices and license are provided solely for your information. {0}, not the third party, licenses this FOSS to you under the terms in which you received the {0} software or the services. Unless applicable law gives you more rights, {0} reserves all other rights not expressly granted under such agreements, whether by implication, estoppel or otherwise. If you wish to obtain access to the source code to which you are entitled under the applicable FOSS license(s) (such as GNU Lesser/General Public License), or if you have any questions regarding FOSS, you may send your request in writing to:',
    },

    charts: {
        singular: (n) => `${n} Chart`,
        plural: (n) => `${n} Charts`,

        defaultTitle: (n) => `Chart ${n}`,
    },

    filters: {
        toggleFilters: 'Show/hide filters',
        title: 'Filters',
    },

    dialog: {
        cancel: 'Cancel',
        ok: 'OK',
    },

    editChartDialog: {
        title: 'Chart Options',
        addForecasts: 'Add Forecast(s)',
        titleLabel: 'Chart Title',
        forecastsLabel: 'Chart Forecasts/Series',
    },

    addForecastsDialog: {
        title: 'Add New Forecasts',
        clearAllSelections: 'Clear All Selections',
        forecastType: 'Forecast Type',
        seriesType: 'Series Type',
        axisType: 'Axis',
        nameSeries: 'Name Series Using These Fields',
        availableForecasts: 'Available Forecasts',
    },

    deleteConfirmation: {
        title: 'Please Confirm',
        question: (chartTitle) => `Are you sure you want to delete "${chartTitle}"?`,
    },

    selectedForecasts: {
        colNameTitle: 'Name',
        colTypeTitle: 'Series Type',
        axisTypeTitle: 'Axis',
        stackId: 'Stack',
        moveUp: 'Move this forecast up',
        moveDown: 'Move this forecast down',
        delete: 'Remove this forecast from the chart',
    },

    loadIndicator: {
        caption: 'Loading, please wait...',
    },
};