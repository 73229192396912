import React from 'react';
import { Dialog as WebCoreDialog } from 'webcore-ux/nextgen/components';

import Locale from 'locale/Locale';
const t = Locale.getResourceString.bind(Locale);

export default (props) => {
    return (
        <WebCoreDialog
            className="delete-chart-confirmation"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={props.isOpen}
            onClose={() => props.onClose?.call()}
            title={t('deleteConfirmation.title')}
            showCloseButton={false}
            disablePortal={true}
            variant="warning"
            actionButtons={[
                {
                    key: 'myCancelButton',
                    variant: 'secondary-discrete',
                    text: t('dialog.cancel'),
                    handleClick: () => props.onCancel?.call(),
                },
                {
                    key: 'myOKButton',
                    variant: 'warning',
                    text: t('dialog.ok'),
                    handleClick: () => props.onOk?.call(),
                },
            ]}
        >
            {t('deleteConfirmation.question')(props.chart?.title)}
        </WebCoreDialog>
    );
}
