import React from 'react';
import Paper from "@material-ui/core/Paper";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
    RowDetailState,
    EditingState,
    DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    TableHeaderRow,
    VirtualTable,
    TableRowDetail,
    TableInlineCellEditing,
} from "@devexpress/dx-react-grid-material-ui";

import Trash from 'webcore-ux/nextgen/components/Icons/Trash';

import SeriesTypes from 'data/chart/SeriesTypes';
import AxisTypes from 'data/chart/AxisTypes';
import Styles from './EditChartDialog.scss';

import Locale from 'locale/Locale';
const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// Gets the unique ID for a grid row.  Here, it's the forecast ID.
////////////////////////////////////////////////////////////////////////////////
const getRowId = row => row.id;

////////////////////////////////////////////////////////////////////////////////
// Gets the units for a specified forecast
////////////////////////////////////////////////////////////////////////////////
const getUnits = (forecast, filters) => {
    return filters?.lookups?.forecastTypes[forecast?.definition?.forecast_type_id]?.units;
}

////////////////////////////////////////////////////////////////////////////////
// Implements the forecast detail row shown when a forecast row is expanded
////////////////////////////////////////////////////////////////////////////////
const ForecastRowDetail = ({ row }) => {
    // Add all descriptive fields
    const detail = Object.values(row.schema)
        .filter(c => c.visibility === 'visible')
        .sort((a, b) => a.index - b.index)
        .map(c => { return (
            <div key={c.name}>
                <span className='forecast-detail-caption'>{c.caption}:&nbsp;</span>
                <span className='forecast-detail-value'>{row.definition[c.name]}</span>
                <br/>
            </div>
        )});

    return (
        <div>
            {detail}
        </div>
    );
};

////////////////////////////////////////////////////////////////////////////////
// Implements the select component for choosing a series type
////////////////////////////////////////////////////////////////////////////////
const SeriesTypeEditor = ({ value, onValueChange, onBlur, autoFocus }) => (
    <Select
        input={<Input />}
        style={{ width: '100%' }}
        value={value}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={event => onValueChange(event.target.value)}
    >
        {SeriesTypes.all.map(type => (<MenuItem key={type} value={type}>{type}</MenuItem>))}
    </Select>
);

const SeriesTypeTypeProvider = props => (
    <DataTypeProvider
        editorComponent={SeriesTypeEditor}
        {...props}
    />
);

const AxisTypeTypeProvider = props => { 
    ////////////////////////////////////////////////////////////////////////////////
    // Implements the component for choosing an axis for a series
    ////////////////////////////////////////////////////////////////////////////////
    const AxisTypeEditor = ({ value, onValueChange, onBlur, autoFocus, row }) => { 
        let units = getUnits(row, props.filters);
        let options = [];
        if (units === props.primaryUnits || props.primaryUnits == null)
            options.push(<MenuItem key={AxisTypes.Primary} value={AxisTypes.Primary}>{AxisTypes.Primary}</MenuItem>);
        if (units === props.secondaryUnits || props.secondaryUnits == null)
            options.push(<MenuItem key={AxisTypes.Secondary} value={AxisTypes.Secondary}>{AxisTypes.Secondary}</MenuItem>);

        return (
            <Select
                input={<Input />}
                style={{ width: '100%' }}
                value={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onChange={event => onValueChange(event.target.value)}
            >
                {options}
            </Select>
        );
    }

    return (
        <DataTypeProvider
            editorComponent={AxisTypeEditor}
            {...props}
        />
    );
};

////////////////////////////////////////////////////////////////////////////////
// Implements the component for choosing a stack ID for Stacked Bar series
////////////////////////////////////////////////////////////////////////////////
const StackIdFormatter = ({row, value}) => (
    (row.seriesType === SeriesTypes.StackedBar) ? value : t('general.notApplicable')
);

const StackIdEditor = ({ row, value, onValueChange, onBlur, autoFocus }) => {
    return (row.seriesType === SeriesTypes.StackedBar) ?
        (<TextField
            type="number"
            value={value}
            autoFocus={autoFocus}
            onBlur={onBlur}
            onChange={event => onValueChange(event.target.value)}
        />) :
        (<TextField
            defaultValue={t('general.notApplicable')}
            autoFocus={autoFocus}
            onBlur={onBlur}
            InputProps={{
                readOnly: true,
            }}            
        />);
}

const StackIdTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={StackIdFormatter}
        editorComponent={StackIdEditor}
        {...props}
    />
);

export default (props) => {
    // These are the columns shown in the forecasts grid
    const columns = [
        { name: 'caption', title: t('selectedForecasts.colNameTitle') },
        { name: 'seriesType', title: t('selectedForecasts.colTypeTitle') },
        { name: 'stackId', title: t('selectedForecasts.stackId') },
        { name: 'axisType', title: t('selectedForecasts.axisTypeTitle') },
        { name: 'moveUp', title: ' ' },
        { name: 'moveDown', title: ' ' },
        { name: 'deleteForecast', title: ' ' },
    ];

    // When row changes are committed, update the rows stored in the state
    const commitChanges = ({ changed }) => {
        let changedRows;

        if (changed) {
            changedRows = props.forecasts.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        }

        props.onChange(changedRows);
    };

    const MoveUpFormatter = ({row}) => (
        <div title={t('selectedForecasts.moveUp')}>
            <ArrowUpwardIcon 
                className="forecast-action-icon"
                fontSize="small" 
                onClick={(e) => {
                    let index = props.forecasts.findIndex(f => f.id === row.id);
                    if (index >= 1) {
                        let forecast = props.forecasts[index];
                        let newForecasts = props.forecasts.slice();
                        newForecasts.splice(index, 1);
                        newForecasts.splice(index - 1, 0, forecast);
                        props.onChange(newForecasts);
                    }
                    e.stopPropagation();
                }}
            />
        </div>
    );
    
    const MoveUpTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={MoveUpFormatter}
            {...props}
        />
    );

    const MoveDownFormatter = ({row}) => (
        <div title={t('selectedForecasts.moveDown')}>
            <ArrowDownwardIcon 
                className="forecast-action-icon"
                fontSize="small" 
                onClick={(e) => {
                    let index = props.forecasts.findIndex(f => f.id === row.id);
                    if (index < props.forecasts.length - 1) {
                        let forecast = props.forecasts[index];
                        let newForecasts = props.forecasts.slice();
                        newForecasts.splice(index, 1);
                        newForecasts.splice(index + 1, 0, forecast);
                        props.onChange(newForecasts);
                    }
                    e.stopPropagation();
                }}
            />
        </div>
    );
    
    const MoveDownTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={MoveDownFormatter}
            {...props}
        />
    );

    const DeleteForecastFormatter = ({row}) => (
        <div title={t('selectedForecasts.delete')}>
            <Trash 
                className="forecast-action-icon"
                fontSize="small" 
                onClick={(e) => {
                    props.onChange(props.forecasts.filter(r => row.id !== r.id));
                    e.stopPropagation();
                }}
            />
        </div>
    );
    
    const DeleteForecastTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={DeleteForecastFormatter}
            {...props}
        />
    );

    const primaryUnits = getUnits(props.forecasts.filter(f => f.axisType === AxisTypes.Primary)[0], props.filters);
    const secondaryUnits = getUnits(props.forecasts.filter(f => f.axisType === AxisTypes.Secondary)[0], props.filters);

    return (
        <>
            <Paper className="app-selected-forecasts-paper">
                <Grid 
                    rows={props.forecasts} 
                    columns={columns}
                    getRowId={getRowId}
                >
                    <SeriesTypeTypeProvider
                        for={['seriesType']}
                    />                    
                    <AxisTypeTypeProvider
                        for={['axisType']}
                        filters={props.filters}
                        primaryUnits={primaryUnits}
                        secondaryUnits={secondaryUnits}
                    />                    
                    <StackIdTypeProvider
                        for={['stackId']}
                    />                    
                    <MoveUpTypeProvider
                        for={['moveUp']}
                    />                    
                    <MoveDownTypeProvider
                        for={['moveDown']}
                    />                    
                    <DeleteForecastTypeProvider
                        for={['deleteForecast']}
                    />                    
                    <EditingState
                        onCommitChanges={commitChanges}
                    />                   
                    <RowDetailState />
                    <VirtualTable 
                        height={`calc(100vh - ${Styles.editChartPaperAdjustment})`}
                        columnExtensions={[
                            { columnName: 'seriesType', width: 120 },
                            { columnName: 'stackId', width: 120 },
                            { columnName: 'axisType', width: 120 },
                            { columnName: 'moveUp', width: 32 },
                            { columnName: 'moveDown', width: 32 },
                            { columnName: 'deleteForecast', width: 44 }
                        ]}
                    />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={ForecastRowDetail} />
                    <TableInlineCellEditing 
                        startEditAction="click"
                    />
                </Grid>
            </Paper>
        </>
    );
}