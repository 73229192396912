import React from 'react';
import AppConfig from 'AppConfig';
import ErrorState from 'ErrorState';
import Locale from 'locale/Locale';

import {Dialog} from 'webcore-ux/nextgen/components';

const t = Locale.getResourceString.bind(Locale);

export default (props) => {
    // Bail if we don't have any error state 
    if (!props.isFaulted || !ErrorState.isFaulted) {
        return null;
    }

    // Get application name and error caption in English for email to English-speaking support
    const applicationName = t('header.productName');
    const subject = encodeURIComponent(`${applicationName} Application Error`);
    const getEmailBody = (fault) => {
        let now = new Date();
        return encodeURIComponent(AppConfig.support.name + ',') +
            '%0d%0a%0d%0a' +
            encodeURIComponent(
                'The following error occurred on ' +
                    now.toLocaleDateString() +
                    ' at ' +
                    now.toLocaleTimeString() +
                    ' while using "' +
                    applicationName +
                    '":'
            ) +
            '%0d%0a%0d%0a' +
            encodeURIComponent(fault)
    }
    const getFaultMessage = (fault) => {
        return (
            <>
                <p>The following error occurred while processing your request.</p>
                <p style={{ textIndent: '2.0em' }}>
                    <i>&quot;{fault}&quot;</i>
                </p>
                <p>Please try refreshing the page, or try again later.  If the problem persists, please contact customer support.</p>
            </>
        );
    }
    const getContent = (message, emailBody) => {
        return (
            <div style={{ whiteSpace: 'normal' }}>
                {message}
                <span><b>Support Inquiries:</b></span>
                <br />
                <a
                    href={
                        'mailto:' +
                        AppConfig.support.email +
                        '?subject=' +
                        subject +
                        '&body=' +
                        emailBody
                    }
                >
                    {AppConfig.support.email}
                </a>
                <br />
                <span>{AppConfig.support.phone}</span>
            </div>
        );
    }
    const actionButtons = () => {
        let actionButtons = {};
        actionButtons[ErrorState.faultCategories.Default] = [
            {
                key: 'fault-refresh',
                variant: 'primary',
                text: 'Refresh',
                handleClick: () => props.onRefresh?.call(),
            },
            {
                key: 'fault-ok',
                variant: 'primary',
                text: 'OK',
                handleClick: () => props.onOk?.call(),
            },
        ];
        actionButtons[ErrorState.faultCategories.Fatal] = [
            {
                key: 'fault-refresh',
                variant: 'primary',
                text: 'Refresh',
                handleClick: () => props.onRefresh?.call(),
            },
            {
                key: 'fault-sign-out',
                variant: 'primary',
                text: t('header.signOut'),
                handleClick: () => props.onSignOut?.call(),
            },
        ];
        return actionButtons;
    }

    // Build email content
    let fault = ErrorState.fault;
    let message = getFaultMessage(fault);
    let emailBody = getEmailBody(fault);
    let content = getContent(message, emailBody);

    return (
        <Dialog
            className="fault-dialog" 
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={true}
            // onClose={() => setIsOpen(false)}
            title="Application Error"
            showCloseButton={false}
            disablePortal={true}
            actionButtons={actionButtons()[ErrorState.faultCategory]}
        >
            {content}
        </Dialog>
    );
}