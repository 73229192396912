const SeriesTypes = Object.freeze({
    Area: "Area",
    Bar: "Bar",
    Line: "Line",
    Scatter: "Scatter",
    StackedBar: "Stacked Bar",

    get default() {
        return this.Line;
    },

    get all() {
        return [
            this.Area,
            this.Bar,
            this.Line,
            this.Scatter,
            this.StackedBar,
        ];
    }
});

export default SeriesTypes;