const AxisTypes = Object.freeze({
    Primary: "Primary",
    Secondary: "Secondary",

    get default() {
        return this.Primary;
    },

    get all() {
        return [
            this.Primary,
            this.Secondary,
        ];
    }
});

export default AxisTypes;