import Chart from './Chart';

class Charts {
    constructor() {
        this._collection = [];
    }
    
    restoreFromJSON(json) {
        // Recreate the Chart
        let obj = JSON.parse(json);
        this._collection = obj._collection.map(c => Chart.fromJSON(c));
    }

    toJSON() {
        // Create a copy of the object, transforming nested fields as needed
        let obj = Object.assign({}, this);
        obj._collection = this._collection.map(c => c.toJSON());

        return JSON.stringify(obj);
    }

    add(chart) {
        if (chart instanceof Chart) {
            this._collection.push(chart);
        }
    }

    remove(chart) {
        if (chart instanceof Chart) {
            let index = this.indexOf(chart);
            if (index !== -1) {
                this._collection.splice(index, 1);
            }
        }
    }

    indexOf(chart) {
        let index = -1;
        if (chart instanceof Chart) {
            index = this._collection.findIndex(c => c.id === chart.id);
        }
        return index;
    }

    clear() {
        if (Array.isArray(this._collection)) {
            this._collection.length = 0;
        }
    }

    getById(id) {
        return this._collection.find(c => c.id === id);
    }

    contains(id) {
        return this.getById(id) != null;
    }

    get all() {
        return this._collection.slice();
    }
}

const _instance = new Charts();
Object.seal(_instance);

export default _instance;
