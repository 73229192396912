import { v4 as uuidv4 } from 'uuid';
import SeriesTypes from './SeriesTypes';
import AxisTypes from './AxisTypes';

export default class Forecast {
    constructor(
        schema, 
        definition, 
        seriesType = SeriesTypes.default, 
        caption = Forecast.getDefaultCaption(schema, definition),
        axisType = AxisTypes.default,
        stackId = 1)
    {
        // Validate some arguments
        if (schema == null) {
            throw new Error('Forecast schema is required');
        }
        if (definition == null) {
            throw new Error('Forecast definition is required');
        }

        // Assign an ID
        this.id = uuidv4();

        this.schema = schema;
        this.definition = definition;
        this.seriesType = seriesType;
        this.caption = (caption == null || caption.trim().length === 0) ? Forecast.getDefaultCaption(schema, definition) : caption;
        this.axisType = axisType;
        this.stackId = stackId;
        
        this.data = null;
    }

    static createFrom(obj) {
        // Copy most of the fields using the ctor
        let forecast = new Forecast(obj.schema, obj.definition, obj.seriesType, obj.caption, obj.axisType, obj.stackId);

        // Copy over the id if it is present in the source
        if (obj.hasOwnProperty('id')) {
            forecast.id = obj.id;
        }

        // Set data to null
        forecast.data = null;

        return forecast;
    }

    static fromJSON(json) {
        // Recreate the Forecast
        let obj = JSON.parse(json);
        return Forecast.createFrom(obj);
    }

    toJSON() {
        // Create a copy of the object and exclude the data
        let obj = Object.assign({}, this);
        obj.data = null;

        return JSON.stringify(obj);
    }

    clear() {
        if (Array.isArray(this.data)) {
            this.data.length = 0;
        }
    }

    update(forecast) {
        this.seriesType = forecast?.seriesType ?? this.seriesType;
        this.caption = forecast?.caption ?? this.caption;
        this.axisType = forecast?.axisType ?? this.axisType;
        this.stackId = forecast?.stackId ?? this.stackId;
    }

    getCaptionFromColumns(columns) {
        return Forecast.getCaptionFromColumns(columns, this.definition);
    }

    static getAllCaptionColumns(schema) {
        return Object.values(schema)
            .filter(col => col.visibility === 'visible' && col.name !== 'forecast_type_name')
            .sort((a, b) => a.index - b.index);
    }

    static getDefaultCaptionColumns(schema) {
        return Object.values(schema)
            .filter(col => col.visibility === 'visible' && col.name !== 'forecast_type_name' && col.name !== 'area_of_the_world_name')
            .sort((a, b) => a.index - b.index);
    }

    static getCaptionFromColumns(columns, definition) {
        return columns
            .reduce((str, col, index) => str += `${(index > 0) ? ', ' : ''}${definition[col.name]}`, '')
            .trim();
    }
    
    static getDefaultCaption(schema, definition) {
        return Forecast
            .getDefaultCaptionColumns(schema)
            .reduce((str, col, index) => str += `${(index > 0) ? ', ' : ''}${definition[col.name]}`, '')
            .trim();
    }
}