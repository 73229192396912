import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Button, Input, Popup } from 'webcore-ux/nextgen/components';

import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Chart from 'data/chart/Chart';

import SelectedForecasts from './SelectedForecasts';
import AddForecastsDialog from './AddForecastsDialog';

import Locale from 'locale/Locale';
const t = Locale.getResourceString.bind(Locale);

// Provides an upward-sliding effect when the dialog opens
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#f2f2f2',
        color: '#333333',
    },
    dialogTitle: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    addForecasts: {
        marginLeft: theme.spacing(1),
    },
    cancel: {
        marginLeft: theme.spacing(3),
    },
    ok: {
        marginLeft: theme.spacing(1),
    },
}));

export default (props) => {
    const classes = useStyles();

    const [title, setTitle] = useState(Chart.defaultTitle);
    const [forecasts, setForecasts] = useState([]);
    const [isAddForecastsVisible, setIsAddForecastsVisible] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState();

    const ChartTitlePopup = (props) => {
        return (
            <Popup closeOnOutsideClick={false} placement="bottom-start" anchorEl={props.anchorElement} isOpen={props.isOpen}>
                <div className="chart-title-popup-content">
                    <span>Chart titles must be at most 128 characters long, and contain only letters, numbers, underscores, hyphens, periods, and spaces.</span>
                    <br /><br />
                    <Button onClick={() => { setIsPopupOpen(false); setAnchorElement(null); }}>OK</Button>
                </div>
            </Popup>                
        );
    }

    const updatedForecastCount = () => {
        return Number(forecasts?.length);
    }

    const onChangeTitle = (event) => {
        let value = event.target.value;
        if (value.match(/^[\w\-. ]{0,128}$/)) {
            setTitle(value);
        }
        else {
            setIsPopupOpen(true);
            setAnchorElement(event.target);
        }
    }

    const chartForecasts = () => {
        return props.chart?.forecasts ?? [];
    }

    const editorForecasts = () => {
        return forecasts;
    }

    const onOk = () => {
        // Update the chart title
        props.chart.title = (title != null && title.trim().length > 0) ? title : Chart.defaultTitle;

        // Remove from the chart all forecasts that were removed in this edit dialog
        chartForecasts()
            .filter(a => forecasts.findIndex(b => b.id === a.id) === -1)
            .forEach(f => props.chart.removeForecast(f));

        // Add new forecasts and update existing ones
        props.chart.applyEditorForecasts(forecasts);

        // Invoke callback
        props.onOk(props.chart);
    };

    return (
        <Dialog
            className="edit-chart-dialog"
            fullScreen
            open={props.isOpen}
            onEnter={() => { 
                setTitle(props.chart?.title ?? Chart.defaultTitle); 
                setForecasts(chartForecasts());
                setIsAddForecastsVisible(props.isAddingChart);
            }}
            onClose={() => props.onCancel()}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => props.onCancel()} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                    <Typography className={classes.dialogTitle} variant="h6" >
                        {t('editChartDialog.title')}
                    </Typography>

                    <Button 
                        className={classes.addForecasts} 
                        disabled={editorForecasts().length >= AppConfig.limits.maxForecasts} 
                        variant="primary" 
                        onClick={() => setIsAddForecastsVisible(true)}
                    >
                        {t('editChartDialog.addForecasts')}
                    </Button>

                    <Button className={classes.cancel} variant="primary" onClick={() => props.onCancel()}>
                        {t('dialog.cancel')}
                    </Button>

                    <Button 
                        className={classes.ok} 
                        disabled={updatedForecastCount() === 0} 
                        variant="primary" 
                        onClick={onOk}
                    >
                        {t('dialog.ok')}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="edit-chart-dialog-content">
                <Input 
                    className=""
                    label={t('editChartDialog.titleLabel')} 
                    placeholder="Enter a title for the chart"
                    value={title}
                    onChange={onChangeTitle}
                />
                <ChartTitlePopup isOpen={isPopupOpen} anchorElement={anchorElement} />

                <label className="wcux-nxt-label">{t('editChartDialog.forecastsLabel')}</label>
                <SelectedForecasts
                    filters={props.filters}
                    forecasts={forecasts}
                    onChange={f => setForecasts(f)}
                />
            </div>

            <AddForecastsDialog 
                isOpen={isAddForecastsVisible}
                filters={props.filters}
                chart={props.chart}
                existingForecasts={editorForecasts()}
                onCancel={() => setIsAddForecastsVisible(false)}
                onOk={(chart, newForecasts) => {
                    // Trace the event
                    AppTrace.traceInfo('A forecast has been added to a chart', AppTrace.categories.addForecast);

                    setForecasts(forecasts.concat(newForecasts));
                    setIsAddForecastsVisible(false);
                }}
            />
        </Dialog>
    );
}