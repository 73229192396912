import React from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Label,
    Tooltip,
    Legend,
    Area,
    Bar,
    Line,
    Scatter,
} from 'recharts';

import Locale from 'locale/Locale';

import Charts from 'data/chart/Charts';
import Palette from 'data/chart/Palette';
import SeriesTypes from 'data/chart/SeriesTypes';
import AxisTypes from 'data/chart/AxisTypes';

import HorizontalSplitContainer from 'common/HorizontalSplitContainer';
import RefcaseChartData from './RefcaseChartData';

import ChartStyles from './RefcaseChart.scss';
import 'components/ReCharts.scss';

export default (props) => {
    // Bail if we don't have anything to chart    
    if (props.chartId == null || !Charts.contains(props.chartId)) {
        return null;
    }

    // Gets series for a collection of forecasts
    const getSeries = (forecasts) => {
        return forecasts.map((f, index) => {
            let series = null;
            const color = Palette.default.getColorByIndex(index);
            const numberFormat = props.filters.lookups.forecastTypes[f.definition?.forecast_type_id]?.format ?? '{value:n2}';
            const formatter = (value) => `${Locale.formatValue(value, numberFormat)}`;

            switch (f.seriesType) {
                case SeriesTypes.Area:
                    series = (<Area isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} fill={color} stroke={color} strokeWidth={2} yAxisId={f.axisType} formatter={formatter} />);
                    break;
                case SeriesTypes.Bar:
                    series = (<Bar isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} fill={color} yAxisId={f.axisType} formatter={formatter} />);
                    break;
                case SeriesTypes.Line:
                    series = (<Line isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} stroke={color} strokeWidth={2} dot={false} yAxisId={f.axisType} formatter={formatter} />);
                    break;
                case SeriesTypes.Scatter:
                    series = (<Scatter isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} fill={color} stroke={color} strokeWidth={1} yAxisId={f.axisType} formatter={formatter} />);
                    break;
                case SeriesTypes.StackedBar:
                    series = (<Bar isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} fill={color} stackId={f.stackId} yAxisId={f.axisType} formatter={formatter} />);
                    break;
                default: 
                    series = (<Line isAnimationActive={false} key={f.id} dataKey={f.id} name={f.caption} stroke={color} strokeWidth={2} dot={false} yAxisId={f.axisType} formatter={formatter} />);
                    break;
            }
            return series;
        });
    }

    const chart = Charts.getById(props.chartId);
    const primaryYAxisForecasts = chart.primaryYAxisForecasts;
    const secondaryYAxisForecasts = chart.secondaryYAxisForecasts;
    const series = getSeries(chart.forecasts);

    // Get primary Y axis settings
    const y1NumberFormat = props.filters.lookups?.forecastTypes[primaryYAxisForecasts[0]?.definition?.forecast_type_id]?.format ?? '{value:n2}';
    const y1Label = props.filters?.lookups?.forecastTypes[primaryYAxisForecasts[0]?.definition?.forecast_type_id]?.units ?? 'Values';
    const primaryYAxis = (primaryYAxisForecasts.length > 0) && (
        <YAxis 
            tickFormatter={ (value) => `${Locale.formatAxisTick(value, y1NumberFormat)}`}
            width={90}
            yAxisId={AxisTypes.Primary}
        >
            <Label 
                angle={-90} 
                position='insideLeft'
                value={y1Label}
                style={{textAnchor: 'middle'}}
            />
        </YAxis>
    );
    // Get secondary Y axis settings
    const y2NumberFormat = props.filters.lookups?.forecastTypes[secondaryYAxisForecasts[0]?.definition?.forecast_type_id]?.format ?? '{value:n2}';
    const y2Label = props.filters?.lookups?.forecastTypes[secondaryYAxisForecasts[0]?.definition?.forecast_type_id]?.units ?? 'Values';
    const secondaryYAxis = (secondaryYAxisForecasts.length > 0) && (
        <YAxis 
            tickFormatter={ (value) => `${Locale.formatAxisTick(value, y2NumberFormat)}`}
            width={90}
            yAxisId={AxisTypes.Secondary}
            orientation="right"
        >
            <Label 
                angle={90} 
                position='insideRight'
                value={y2Label}
                style={{textAnchor: 'middle'}}
            />
        </YAxis>
    );

    return (
        <HorizontalSplitContainer
            buttonContainerClassName="company-financials-data-buttons"
            isSplit={props.isViewChartData}
            topPanelMinHeight={220}
            topPanelContent={
                <>
                    <span className="refcase-chart-title">{chart.title}</span>
                    <ResponsiveContainer>
                        <ComposedChart
                            data={chart.data}
                            margin={{
                                top: parseInt(ChartStyles.refcaseChartTitleHeight),
                                right: 40,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis 
                                dataKey="argument" 
                            />
                            {primaryYAxis}
                            {secondaryYAxis}
                            <Tooltip
                                isAnimationActive={false}
                            />
                            <Legend />
                            {series}
                        </ComposedChart>
                    </ResponsiveContainer>
                </>
            }
            bottomPanelMinHeight={220}
            bottomPanelContent={
                <RefcaseChartData
                    filters={props.filters}
                    chartId={props.chartId}
                    isViewChartData={props.isViewChartData}
                />
            }
        />
    );
}