import React, { useState } from 'react';
import Paper from "@material-ui/core/Paper";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { TableCell } from '@material-ui/core';
 
import { Button, Dropdown, NotificationContent } from 'webcore-ux/nextgen/components';

import {
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    TableHeaderRow,
    TableFilterRow,
    TableSelection,
    VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";

import AppConfig from 'AppConfig';
import BusyState from 'BusyState';
import ErrorState from 'ErrorState';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import SeriesTypes from 'data/chart/SeriesTypes';
import AxisTypes from 'data/chart/AxisTypes';
import Forecast from 'data/chart/Forecast';
import AvailableForecastsDataFactory from 'data/forecasts/AvailableForecastsDataFactory';

import Styles from './EditChartDialog.scss';

import Locale from 'locale/Locale';
const t = Locale.getResourceString.bind(Locale);

// Provides an upward-sliding effect when the dialog opens
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#f2f2f2',
        color: '#333333',
    },
    dialogTitle: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    clearSelections: {
        marginLeft: theme.spacing(1),
    },
    cancel: {
        marginLeft: theme.spacing(3),
    },
    ok: {
        marginLeft: theme.spacing(1),
    },
}));

////////////////////////////////////////////////////////////////////////////////
// Custom filtering algorithm that supports multiple possible filter values
// for a field, separated by a pipe character.
////////////////////////////////////////////////////////////////////////////////
const forecastPredicate = (value, filter) => {
    if (filter.value != null) {
        const values = filter.value.split("|").reduce((obj, v) => {
            obj[v] = true;
            return obj;
        }, {});
        return values.hasOwnProperty(String(value));
    }
    else {
        return false;
    }
};

////////////////////////////////////////////////////////////////////////////////
// Custom grid filter header cell that renders as a multi-select dropdown
// to allow users to see and choose from just the values that are available.
////////////////////////////////////////////////////////////////////////////////
const DropdownFilterCell = ({column, filter, onFilter}) => {
    const filterValueOptionsArray = column.availableFilterValues ?? [];
    const filterValue = filter?.value?.split('|') ?? [];
    return (
        <TableCell style={{width: '100%', padding: "8px", margin: 0 }}>
            <MultiSelectDropdown
                options={filterValueOptionsArray.map(v => { return { label: v, value: v }})}
                value={filterValue}
                onChange={(value) => {
                    if (value?.length > 0) {
                        onFilter({ columnName: column.name, operation: 'equal', value: value.join("|") });
                    } 
                    else {
                        onFilter(null);
                    }
                }}
            />
        </TableCell>
    );
};

export default (props) => {
    const classes = useStyles();
    
    const [forecastTypesOptions, setForecastTypesOptions] = useState([]);
    const [forecastType, setForecastType] = useState();
    const [forecastSchema, setForecastSchema] = useState({});
    const [rows, setRows] = useState([]);
    const [allFilterValues, setAllFilterValues] = useState({});
    const [columns, setColumns] = useState([]);
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [selection, setSelection] = useState([]);
    const [seriesType, setSeriesType] = useState(SeriesTypes.default);
    const [axisTypesOptions, setAxisTypesOptions] = useState(AxisTypes.all.map(type => { return { label: type, value: type }}));
    const [axisType, setAxisType] = useState(AxisTypes.default);
    const [nameFields, setNameFields] = useState([]);
    const [userSetNameFields, setUserSetNameFields] = useState(false);
    const NotificationHeight = 48;

    const primaryYAxisForecasts = props.existingForecasts.filter(f => f.axisType === AxisTypes.Primary);
    const secondaryYAxisForecasts = props.existingForecasts.filter(f => f.axisType === AxisTypes.Secondary);
    const primaryUnits = props.filters.lookups.forecastTypes[primaryYAxisForecasts[0]?.definition?.forecast_type_id]?.units ?? '$/MWh';
    const secondaryUnits = props.filters.lookups.forecastTypes[secondaryYAxisForecasts[0]?.definition?.forecast_type_id]?.units ?? '$/MWh';
    const allForecastTypes = (props.filters?.forecastTypes ?? []);
    const seriesTypesOptions = SeriesTypes.all.map(type => { return { label: type, value: type }});
  
    //
    // Derive the available forecast types based on the forecast/axis combinations already in the chart
    //
    let availableForecastTypes = [];
    // If the granularityId is null, this is a new chart so take all forecast types
    if (props.chart.granularityId == null) {
        availableForecastTypes = allForecastTypes;
    }
    // If we have no forecasts, OR
    // If we have forecasts on only 0 or 1 axis (i.e. we have a whole axis to which we could assign forecasts)
    // Then we take all forecast types that match the granularity ID
    else if (primaryYAxisForecasts.length === 0 || secondaryYAxisForecasts.length === 0) {
        availableForecastTypes = allForecastTypes.filter(ft => ft.granularityId === props.chart.granularityId);
    }
    // Otherwise, we will need to match granularityId and units
    else {
        availableForecastTypes = allForecastTypes.filter(ft => {
            let isGranularityGood = ft.granularityId === props.chart.granularityId;
            let units = ft.units ?? '$/MWh';
            let areUnitsGood = units === primaryUnits || units === secondaryUnits;
            return isGranularityGood && areUnitsGood;
        });
    }

    // The available axis types and selected axis type depend on the forecast type and current forecast/axis selections
    const onUpdateAxisTypes = (forecastTypeId) => {
        let newAxisTypesOptions = [];
        // If we have no forecasts on the primary axis, OR the primary units match the forecast type, we can support it
        if (primaryYAxisForecasts.length === 0 || primaryUnits === allForecastTypes.find(at => at.id === forecastTypeId)?.units) {
            newAxisTypesOptions.push({ label: AxisTypes.Primary, value: AxisTypes.Primary });
        }
        // If we have no forecasts on the secondary axis, OR the secondary units match the forecast type, we can support it
        if (secondaryYAxisForecasts.length === 0 || secondaryUnits === allForecastTypes.find(at => at.id === forecastTypeId)?.units) {
            newAxisTypesOptions.push({ label: AxisTypes.Secondary, value: AxisTypes.Secondary });
        }
        
        // Update the axis type options, and if needed, the axis type
        setAxisTypesOptions(newAxisTypesOptions);
        if (newAxisTypesOptions.find(ato => ato.value === axisType) == null) {
            setAxisType(newAxisTypesOptions[0].value);
        }
    }
    
    const onChangeForecastType = (forecastTypeId) => {
        // Update the forecast type in state
        setForecastType(forecastTypeId);

        // Load available forecasts for the new selected type
        BusyState.isBusy = true;
        AvailableForecastsDataFactory
            .get(forecastTypeId)
            .then(forecasts => {
                let filterValues = JSON.parse(JSON.stringify(forecasts.transformed.allFilterValues));
                let schema = forecasts.transformed.columns;
                let columns = Forecast.getAllCaptionColumns(schema)
                    .map(c => { return { name: c.name, title: c.caption, availableFilterValues: filterValues?.[c.name] ?? [] }});

                // Forecast schema must be set before setting intuitive name fields
                setForecastSchema(schema);

                // The available axis types and selected axis type depend on the forecast type and current forecast/axis selections
                onUpdateAxisTypes(forecastTypeId);

                // Set remaining fields
                setNameFieldsIntuitively(filters, schema);
                setAllFilterValues(filterValues);
                setColumns(columns);
                setRows(forecasts.transformed.rows);

                // Grid filters must be updated to exclude filters on any columns not present in the new schema
                // Since filters can reference columns that are valid in different schemas but have different
                // sets of values (e.g. scenario, subscenario, etc.), just play it safe and remove all filters.
                setFilters([]);
            })
            .catch(error => {
                console.error(error.message);
                ErrorState.setFault(error.message, ErrorState.faultCategories.Fatal);
            })
            .finally(() => {
                BusyState.isBusy = false;
            });
    }

    const onChangeNameFields = (value) => {
        setNameFields(value);
        setUserSetNameFields(true);
    }

    const setNameFieldsIntuitively = (filters, schema) => {
        // If the user hasn't specifically chosen series fields, then update those.
        if (!userSetNameFields) {
            // I think it's a good guess as to what the user cares about for series to 
            // use the column(s) that are NOT filtered for something
            var filterColumnNames = filters.reduce((obj, f) => {
                obj[f.columnName] = true;
                return obj;
            }, {});
            setNameFields(
                Forecast.getDefaultCaptionColumns(schema)
                    .filter(c => !filterColumnNames.hasOwnProperty(c.name))
                    .map(c => c.name)
            );
        }
    }

    const onChangeFilters = (filters) => {
        let filterValues = {};
        try {
            // Build new dropdown filter values by looking at the data and current filters
            // This will allow clients to see only filter values that are applicable given
            // their current selections.
            for (const columnName in allFilterValues) {
                // Get filters that are applied to all OTHER columns - each columns available
                // filters will be based on what's left when applying filters from all OTHER 
                // columns.
                const otherColumnFilters = filters.filter(f => f.columnName !== columnName);
                const temp = rows.reduce((obj, row) => {
                    // Start out assuming the field value WILL BE available as a filter value in the dropdown
                    let includeRow = true;
                    for (let i = 0; i < otherColumnFilters.length && includeRow; ++i) {
                        let filter = otherColumnFilters[i];
                        let values = filter.value.split("|").reduce((obj, v) => { obj[v] = true; return obj; }, {});
                        // Continue to include the field value for this row as long as this row 
                        // meets the current filter criteria
                        includeRow = includeRow && values.hasOwnProperty(row[filter.columnName]);
                    }

                    // Track included rows' field value as a dropdown filter value
                    if (includeRow && row[columnName] != null)
                        obj[row[columnName]] = true;

                    return obj;
                }, {});

                // Sort the filter values
                filterValues[columnName] = AvailableForecastsDataFactory.sortFilterValues(Object.keys(temp), columnName);
            }
        }
        catch (err) {
            // Something went sideways, just use all available filter values for each dropdown
            filterValues = JSON.parse(JSON.stringify(allFilterValues));
        }

        // Update the columns with the new filter values
        columns.forEach(c => c.availableFilterValues = filterValues[c.name] ?? []);

        // Update columns and filters
        setColumns(columns);
        setFilters(filters);
        setNameFieldsIntuitively(filters, forecastSchema);
    }

    const onOk = () => {
        // Assign the chart's granularity to match the selected forecast type
        props.chart.granularityId = props.filters?.forecastTypes?.find(ft => ft.id === forecastType)?.granularityId;

        // Find the maximum existing stack ID - we'll use this + 1 as the new stack ID
        let existingStackedBars = props.existingForecasts.filter(f => f.seriesType === SeriesTypes.StackedBar);
        let maxStackId = (existingStackedBars.length === 0) ? 
            0 :
            Math.max.apply(Math, existingStackedBars.map(f => f.stackId ?? 0));

        props.onOk(
            props.chart,
            selection.map(s => new Forecast(
                forecastSchema, 
                // This is the forecast definition
                rows[s], 
                seriesType, 
                Forecast.getCaptionFromColumns(nameFields.map(field => forecastSchema[field]), rows[s]),
                axisType,
                maxStackId + 1
            ))
        );
    };

    const totalForecasts = (selection?.length ?? 0) + (props.existingForecasts?.length ?? 0);
    const tooManyForecasts = totalForecasts > AppConfig.limits.maxForecasts;
    const gridHeight = `calc(100vh - ${Styles.addForecastPaperAdjustment} - ${tooManyForecasts ? NotificationHeight : 0}px)`;

    return (
        <Dialog
            className="add-forecasts-dialog"
            fullScreen
            open={props.isOpen}
            onEnter={() => { 
                // Derive the initial forecastTypesOptions
                let newForecastTypesOptions = availableForecastTypes.map(ft => { return { label: ft.name, value: ft.id }});
                // Set the available forecast types options
                setForecastTypesOptions(newForecastTypesOptions);
                // Set selected forecast type - try to use previously-selected forecast type; if not available, use first available
                let newForecastType = newForecastTypesOptions.find(fto => fto.value === forecastType)?.value ?? newForecastTypesOptions[0]?.value;
                onChangeForecastType(newForecastType);
                // Clear pre-existing selections
                setSelection([]);
                // Assume user has not selected name fields
                setUserSetNameFields(false);
            }}
            onClose={() => props.onCancel()}
            TransitionComponent={Transition}
        >
            {tooManyForecasts && (<NotificationContent message={`A maximum of ${AppConfig.limits.maxForecasts} forecasts may be selected.  Please remove at least ${totalForecasts - AppConfig.limits.maxForecasts} selections.`} severity="warning" variant="normal" onClose={() => {}} />)}
            
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => props.onCancel()} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                    <Typography className={classes.dialogTitle} variant="h6" >
                        {t('addForecastsDialog.title')}
                    </Typography>

                    <Button disabled={selection?.length === 0} className={classes.clearSelections} variant="primary" onClick={() => setSelection([])}>
                        {t('addForecastsDialog.clearAllSelections')}
                    </Button>

                    <Button className={classes.cancel} variant="primary" onClick={() => props.onCancel()}>
                        {t('dialog.cancel')}
                    </Button>

                    <Button className={classes.ok} variant="primary" disabled={tooManyForecasts} onClick={onOk}>
                        {t('dialog.ok')}
                    </Button>
                </Toolbar>
            </AppBar>

            <div className="add-forecasts-dialog-content">
                <div className="app-add-forecast-properties">
                    <div className="app-add-forecast-flex">
                        <div className="app-add-forecast-types">
                            <Dropdown 
                                label={t('addForecastsDialog.forecastType')}
                                isClearable={false} 
                                options={forecastTypesOptions} 
                                value={forecastType} 
                                onChange={obj => onChangeForecastType(obj.value)}
                            />
                        </div>
                        <div className="app-add-forecast-series">
                            <Dropdown 
                                label={t('addForecastsDialog.seriesType')}
                                isClearable={false} 
                                options={seriesTypesOptions} 
                                value={seriesType} 
                                onChange={obj => setSeriesType(obj.value)}
                            />
                        </div>
                        <div className="app-add-forecast-axes">
                            <Dropdown 
                                label={t('addForecastsDialog.axisType')}
                                isClearable={false} 
                                options={axisTypesOptions} 
                                value={axisType} 
                                onChange={obj => setAxisType(obj.value)}
                            />
                        </div>
                        <div className="app-add-forecast-names">
                            <MultiSelectDropdown
                                label={t('addForecastsDialog.nameSeries')}
                                options={Forecast.getAllCaptionColumns(forecastSchema).map(c => { return { label: c.caption, value: c.name }})}
                                value={nameFields}
                                onChange={onChangeNameFields}
                            />
                        </div>
                    </div>
                </div>
                
                <label className="wcux-nxt-label">{t('addForecastsDialog.availableForecasts')}</label>
                <Paper style={{ height: gridHeight}}>
                    <Grid rows={rows} columns={columns}>
                        <SortingState 
                            sorting={sorting}
                            onSortingChange={setSorting}
                        />
                        <IntegratedSorting />                    
                        <FilteringState 
                            filters={filters} 
                            onFiltersChange={onChangeFilters}
                        />
                        <IntegratedFiltering 
                            columnExtensions={columns.map(c => { return { columnName: c.name, predicate: forecastPredicate }})}
                        />
                        <SelectionState
                            selection={selection}
                            onSelectionChange={setSelection}
                        />
                        <IntegratedSelection />
                        <VirtualTable 
                            height={gridHeight}
                        />
                        <TableHeaderRow showSortingControls />
                        <TableFilterRow 
                            cellComponent={DropdownFilterCell}
                        />
                        <TableSelection showSelectAll />
                    </Grid>
                </Paper>
            </div>
        </Dialog>
    );
}