import moment from 'moment';
import AppConfig from 'AppConfig';
import AuthToken from 'auth/AuthToken';

const axios = require('axios');

class ForecastDataFactory {
    get(forecastDefinition) {
        return new Promise((resolve, reject) => {
            // If not cached, go get it
            AuthToken
                .getJwt()
                .then(jwt => {
                    let authString = `Bearer ${jwt}`;
                    let url = `${AppConfig.environment.dataService.baseUrl}/forecast/data?parametersJSON=${encodeURIComponent(JSON.stringify(forecastDefinition))}`;

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    })
                })
                .then(response => {
                    // We received the forecast data
                    resolve(this._postProcess(response.data, forecastDefinition));
                })
                .catch((error) => {
                    console.error(error.message);
                    reject(new Error(`Error getting forecast data: "${error.message}"`));
                });
        });
    }

    _parseYearMonth(yearMonth) {
        let tokens = yearMonth.split('-');
        if (tokens.length === 2) {
            return moment({ years: parseInt(tokens[0], 10), month: parseInt(tokens[1], 10) - 1 });
        }
        else {
            throw Error('unable to parse year-month');
        }
    }

    _parseYear(year) {
        return moment({ years: parseInt(year, 10) });
    }

    _postProcess(forecastData, forecastDefinition) {
        const createMoment = (forecastDefinition.granularity_id === 1) ?
            this._parseYearMonth :
            this._parseYear;

        return forecastData.rows.map(row => { 
            return {
                apiArgument: row[0],
                moment: createMoment(row[0]),
                argument: row[0].toString(),
                value: parseFloat(row[1])
            };
        });
    }
}

const _instance = new ForecastDataFactory();
Object.seal(_instance);

export default _instance;
