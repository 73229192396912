import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'webcore-ux/node_modules/react-select';
import { Checkbox } from 'webcore-ux/nextgen/components';

import './MultiSelectDropdown.scss';

////////////////////////////////////////////////////////////////////////////////
// Custom MultiSelect option
////////////////////////////////////////////////////////////////////////////////
const Option = props => {
    return (
        <components.Option {...props}>
            <Checkbox
                key={props.value}
                className="multi-select-checkbox"
                checked={props.isSelected}
                label={props.label}
                name={props.value}
                value={props.value}
            />
        </components.Option>
    );
};

////////////////////////////////////////////////////////////////////////////////
// Custom MultiSelect value
////////////////////////////////////////////////////////////////////////////////
const MultiValue = props => {
    // Render text only once no matter how many values are selected, BUT ONLY IF there is also no search text
    if ((props.index === 0) && (props.selectProps.inputValue == null || props.selectProps.inputValue.length === 0)) {
        let text = props.getValue().map(v => v.label).join(', ');
        return (
            <div className="multiple-selected-items-value" title={text}>
                {text}
            </div>
        );
    }
    else {
        return null;
    }
};

////////////////////////////////////////////////////////////////////////////////
// MultiSelectDropdown renders a list of items, multiples of which can be
// selected.  The items are rendered as checkboxes.
//
// Clicking the items selects or deselects the items.
// 
// Some WebCore-ish styling is applied, etc.
////////////////////////////////////////////////////////////////////////////////
class MultiSelectDropdown extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {

        };

        this._onChange = this._onChange.bind(this);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets all actual options, flattening out the groups heirarchy if present
    ////////////////////////////////////////////////////////////////////////////
    _getAllOptions(options) {
        let allOptions = [];

        for (let i = 0; i < options.length; ++i) {
            let option = options[i];

            // Push the option if it has a non-null value
            if (option.value != null) {
                allOptions.push(option);
            }

            // Visit all children
            if (option.options != null && option.options.length > 0) {
                allOptions.push.apply(allOptions, this._getAllOptions(option.options));
            }
        }

        return allOptions;
    }

    _onChange(value, action) {
        if (this.props.onChange != null) {
            this.props.onChange((value != null) ? 
                value.map(v => v.value) : 
                []
            );
        }
    }

    render() {
        // Flatten out props.options for easier use
        let allPropsOptions = this._getAllOptions(this.props.options);
        // Get options for use in Select component - the component needs
        // label and value properties
        let value = this.props.value
            // Find index of each value in the input options
            .map(v => allPropsOptions.findIndex(option => option.value === v))
            // Filter out any that are not found in props.options
            .filter(index => index >= 0)
            // Get matching option by index
            .map(index => Object.assign({}, allPropsOptions[index]));
        let label = (this.props.label != null) ?
            (<label className="wcux-nxt-label" htmlFor={this.props.id}>{this.props.label}</label>) : 
            null;

        return (
            <div className="de-multi-select-dropdown-root">
                {label}
                <Select
                    id={this.props.id}
                    className={classNames('de-multi-select-dropdown', this.props.className)}
                    closeMenuOnSelect={this.props.closeMenuOnSelect}
                    components={{ MultiValue: (props) => <MultiValue {...props} />, Option: Option }}
                    hideSelectedOptions={false}
                    isClearable={this.props.isClearable}
                    isDisabled={this.props.isDisabled}
                    isMulti={true}
                    isSearchable={this.props.isSearchable}
                    options={this.props.options}
                    placeholder={this.props.placeholder}
                    value={value}
                    onChange={this._onChange}
                />
            </div>
        );
    }
};

MultiSelectDropdown.defaultProps = {
    closeMenuOnSelect: false,
    isClearable: true,
    isDisabled: false,
    isSearchable: true,
    placeholder: 'Select...',
    options: [],
    value: [],
};

MultiSelectDropdown.propTypes = {
    /** Class name for the dropdown control wrapper */
    className: PropTypes.string,
    /** Indicates whether or not to close the dropdown when options are selected/deselected */
    closeMenuOnSelect: PropTypes.bool,
    /** id for the dropdown control */
    id: PropTypes.string,
    /** true to display the clear button */
    isClearable: PropTypes.bool,
    /** true to disable the dropdown */
    isDisabled: PropTypes.bool,
    /** true to enable search/filter on options */
    isSearchable: PropTypes.bool,
    /** Label to display */
    label: PropTypes.string,
    /**
     * Options for the dropdown control including the labels and their corresponding values.
     * Two formats are supported:
     * 
     * (1) An array of options with label and value properties, e.g. 
     * [
     *   { label: 'Priority0', value: 'P0' }, 
     *   { label: 'Priority1', value: 'P1' }
     * ] 
     * 
     * (2) A grouped heirarchy of options.  Each group is an array of options as above.
     *     each group will have a header in the dropdown using the group's label field,
     *     e.g.
     *     [
     *       {
     *         label: 'Group A',
     *         options: [
     *           { label: "Option A 1", value: 'a1' }, 
     *           { label: "Option A 2", value: 'a1' }, 
     *           { label: "Option A 3", value: 'a3' }, 
     *         ],
     *       },
     *       {
     *         label: 'Group B',
     *         options: [
     *           { label: "Option B 1", value: 'b1' }, 
     *           { label: "Option B 2", value: 'b2' }, 
     *         ],
     *       },
     *     ]
     */
    options: PropTypes.array.isRequired,
    /** Placeholder to display when no option is selected */
    placeholder: PropTypes.string,
    /** Function to handle dropdown change event. Signature: function(selectedValueObject) */
    onChange: PropTypes.func,
    /**  Dropdown value(s), required for a controlled component; array if isMulti */
    value: PropTypes.any,
    /** Object containing fields used for the `label` and `value` of the dropdown option */
    optionsProps: PropTypes.shape({
        displayField: PropTypes.string,
        valueField: PropTypes.string,
    }),
};

export default MultiSelectDropdown;
