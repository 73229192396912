import moment from 'moment';

import Impl_en from './en/Impl_en';

class Locale {
    constructor() {
        // This is for event handlers to observe changes in the locale
        this._localeChangedHandlers = {};

        // Data related to text localization
        // NOTE: We don't support other languages yet, but with Mexico and Europe data 
        // in there, I wanted to build this out just in case.
        // let regex = new RegExp('^ja', 'i')
        // let language = navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage
        // this._localeName = regex.test(language) ? 'ja' : 'en';
        this._localeName = 'en';
        this._impls = {
            'en': new Impl_en(),
        };

        // Set locale
        moment.locale(this._localeName);
    }

    // Gets the locale implementation based on the current locale name
    get _impl() {
        return this._impls[this.localeName];
    }

    // Adds a function to be called when the locale changes
    // The handler will receive the name of the locale, but clients can always access
    // the selected locale through this class.
    addLocaleHandler(key, handler) {
        this._localeChangedHandlers[key] = handler;
    }

    // Removes a locale-changed handler
    removeLocaleHandler(key) {
        delete this._localeChangedHandlers[key];
    }

    // Get supported locale names
    get localeNames() {
        return Object.keys(this._impls);
    }

    // Get/set locale name, which represents the selected locale
    get localeName() {
        return this._localeName;
    }
    set localeName(localeName) {
        if (this._localeName !== localeName) {
            // Set our own localeName property
            this._localeName = localeName;

            // Configure the moment library
            moment.locale(localeName);

            // Notify observers
            this._notifyLocaleChanged();
        }
    }

    // Gets a localized resource string by the string's resource key/identifier
    getResourceString(key, localeName) {
        let resourceString = null;

        if (key != null) {
            localeName = localeName != null ? localeName : this._localeName;
            let obj = this._impls[localeName].resources;
            let keys = key.split('.');

            for (let i = 0; obj != null && i < keys.length; ++i) {
                obj = obj[keys[i]];
            }

            resourceString = obj;
        }

        return resourceString;
    }

    // TODO: Can we use an updated version of this to parse dates for refcase?
    // Parses a date and time, assuming the following:
    // * The input value is formatted as: "2019-03-10T04:00:00"
    // * For JEMI, we will treat all DateTime values as UTC for simplicity, and
    //   we can because Japan doesn't observe DST and all of Japan is in one
    //   time zone.
    parseMoment(value) {
        let trimmed = value.trim();
        if (!trimmed.endsWith("Z")) {
            trimmed = trimmed + "Z";
        }
        return moment(new Date(trimmed)).utcOffset(0);
    }

    // Formats a moment as a date using the current application locale
    formatDate(moment) {
        return moment != null ? this._impl.formatDate(moment) : null;
    }

    // Formats a moment as a date and time using the current application locale
    formatDateTime(moment) {
        return moment != null ? this._impl.formatDateTime(moment) : null;
    }

    // Formats a moment at a specified "granularity"
    formatMomentGranularity(moment, granularity) {
        return moment != null ? this._impl.formatMomentGranularity(moment, granularity) : null;
    }

    // Formats a number according to the configured locale using the specified number of digits
    formatNumber(value, digits) {
        return value != null ? this._impl.formatNumber(value, digits) : null;
    }

    // Formats a number as a percentage according to the configured locale using the specified number of digits
    formatPercent(value, digits) {
        return value != null ? this._impl.formatPercent(value, digits) : null;
    }

    // Formats a currency value 
    formatCurrency(value) {
        return value != null ? this._impl.formatCurrency(value) : null;
    }

    // Formats a forecast value using a format string, which is expected to include
    // {value:nY}, where "Y" is a single digit specifying the number of decimal places
    formatValue(value, formatString) {
        let match = null;
        if ((match = formatString.match(/{value:[Nn](\d){1}}/)) != null) {
            let numericValue = this.formatNumber(value, parseInt(match[1], 10));
            return formatString.replace(match[0], numericValue);
        }
        else {
            throw new Error('Unsupported formatString');
        }
    }

    // Formats a forecast value using a format string, which is expected to include
    // {value:nY}, where "Y" is a single digit specifying the number of decimal places
    formatAxisTick(value, formatString) {
        let match = null;
        if ((match = formatString.match(/{value:[Nn](\d){1}}/)) != null) {
            return this.formatNumber(value, parseInt(match[1], 10));
        }
        else {
            throw new Error('Unsupported formatString');
        }
    }

    // Notifies all event handlers of a change in the selected locale
    _notifyLocaleChanged() {
        let stopNotifying = false;
        let handlers = Object.values(this._localeChangedHandlers);
        for (let i = 0; i < handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || handlers[i](this.localeName);
        }
    }
}

const _instance = new Locale();
Object.seal(_instance);

export default _instance;
